import React from 'react';
import Button from '../../Button/Button';

const CallToAction = () => {
    return (
        <>
            <section className='cta section' id='cta' data-scroll-section>
                <div className='cta__container container'>
                    <div className='cta__data'>
                        <h1 className='cta__title'>Let’s Make Great <br/> Work Together</h1>
                        <a href={`mailto:iwayanadityasemaraputra@gmail.com?subject=Collaboration%20Opportunity&body=${encodeURIComponent("Hello there,\n\nI'm interested in exploring a potential collaboration on a project. Could we discuss further details?\n\nThank you")}`}>
                            <Button text='Start A Project'></Button>
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CallToAction;