import React, { useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import transition from '../Animations/transition';
import SplitType from 'split-type';
import {gsap, ScrollTrigger} from 'gsap/src/all';

import useLocoScroll from '../Animations/useLocoScroll';
import Footer from '../Sections/Footer/Footer';
import Button from '../Button/Button';

const PageNotFound = ({isLoading}) => {
    
    const containerRef = useRef(null)
    const ref = useRef(null)

    
    // const startLocoScroll = true;

    useLocoScroll(!isLoading);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        if(isLoading === false){
            const myTitle = new SplitType('.header__titleLine h1');

            const timeline_home = gsap.timeline({
                // paused: true
            });
            timeline_home.from('.header__title .line .word', {
                duration: 0.8,
                skewY: 15,
                y: 150,
                stagger : 0.2,
                ease: "power2.out",
                delay: 5.5,
            })
            .from('.header__btnContainer .header__item',{
                scale: 0,
                duration: 0.5,
                stagger : 0.2,
                ease: "power2.out",
            }, '-=0.4')
            .from('.header__imageContainer .header__imageItems',{
                clipPath: 'inset(0 0 100% 0)',
                duration: 2.5,
                stagger : 0.2,
                ease: "power2.out",
            }, '-=0.2')
        }
        else{
            const myTitle = new SplitType('.header__titleLine h1');

            const timeline_home = gsap.timeline({
                // paused: true
            });
            timeline_home.from('.header__title .line .word', {
                duration: 0.8,
                skewY: 15,
                y: 150,
                stagger : 0.2,
                ease: "power2.out",
                // delay: 10.5,
            })
            .from('.header__btnContainer .header__item',{
                scale: 0,
                duration: 0.5,
                stagger : 0.2,
                ease: "power2.out",
            }, '-=0.4')
            .from('.header__imageContainer .header__imageItems',{
                clipPath: 'inset(0 0 100% 0)',
                duration: 2.5,
                stagger : 0.2,
                ease: "power2.out",
            }, '-=0.2')
        }
        
        

        gsap.set(".cursor-follower", {xPercent: 50, yPercent: 50});

        const ball = document.querySelector(".cursor-follower");
        const pos = { x: window.innerWidth / 2.5, y: 150 };
        const mouse = { x: pos.x, y: pos.y };
        const speed = 0.03;

        const xSet = gsap.quickSetter(ball, "x", "px");
        const ySet = gsap.quickSetter(ball, "y", "px");

        window.addEventListener("mousemove", e => {    
        mouse.x = e.x;
        mouse.y = e.y;  
        });

        gsap.ticker.add(() => {
        
            // adjust speed for higher refresh monitors
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
            
            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });
    },[])

    return (
        <>
            <Helmet>
                <title>Aditya Semara | Page Not Found</title>
                <meta name="description" content="Aditya Semara - Creative Website Developer and Brand Identity Designer"/>
                <meta name="keywords" content="Aditya Semara, Website Developer, Brand Identity Designer, Creative Design"/>
                <meta name="author" content="Aditya Semara"/>
            </Helmet>
        
            <div data-load-container>
                
                <main id='main-container' data-scroll-container ref={containerRef}>
                    <section className='header' id='header' data-scroll-section>
                        <div className='header__container container'>
                            <div className='header__data'>
                                <div className='header__titleContainer'>
                                    <pre className='pre__title'>
                                        404
                                    </pre>
                                    <div className='header__titleLine'>
                                        <h1 className='header__title'>The Page You were Looking</h1>
                                        <h1 className='header__title'>For Cannot be Found. </h1>
                                    </div>
                                </div>
                            </div>
                            <div className='header__btnContainer'>
                                <div className='header__item header__btn' ref={ref} style={{ gridColumn: 'span 3' }}>
                                    <Link to='/'>
                                        <Button text='Back to home'></Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="cursor-follower" >
                        <div className='blobs1'></div>
                        <div className='blobs2'></div>
                        <div className='blobs3'></div>
                    </div>

                    <Footer/>
                </main>
            </div>
        </>
    );
}

export default transition(PageNotFound);