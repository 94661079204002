// import React, { useRef, useState, useEffect, createRef } from 'react';

// import {gsap} from 'gsap/src/all';

// const ButtonCircle = ({link}) => {
//     useEffect(() => {
//         var mWrap = document.querySelectorAll(".magnetic-wrap");
//         function parallaxIt(e, wrap, movement = 1) {
//             var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//             var boundingRect = wrap.mArea.getBoundingClientRect();
//             var relX = e.pageX - boundingRect.left;
//             var relY = e.pageY - boundingRect.top;
          
//             gsap.to(wrap.mContent, {
//               x: (relX - boundingRect.width / 2) * movement,
//               y: (relY - boundingRect.height / 2 - scrollTop) * movement,
//               ease: "power1",
//               duration: 0.6
//             });
//           }
          
//           mWrap.forEach(function (wrap) {
//             wrap.mContent = wrap.querySelector(".js-magnetic-content");
//             wrap.mArea = wrap.querySelector(".js-magnetic-area");
            
//             wrap.mArea.addEventListener("mousemove", function(e) {
//               parallaxIt(e, wrap);
//             });
            
//             wrap.mArea.addEventListener("mouseleave", function (e) {
//               gsap.to(wrap.mContent, {
//                 scale: 1,
//                 x: 0,
//                 y: 0,
//                 ease: "elastic.out",
//                 duration: 0.6
//               });
//             });
//           });
          
//     })

//     return (
//         <>
//             <a className="magnetic-wrap" href={link}>
//                 <div className="js-magnetic-area magnetic-size"></div>
//                 <div className="js-magnetic-content">
//                     <div className="my-button button-circle">
//                       <i className='bx bx-right-arrow-alt'></i>
//                       See More
//                     </div>
//                 </div>
//             </a>
//         </>
//     );
// };

// export default ButtonCircle;

import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap/src/all';
import { Link } from 'react-router-dom';

const ButtonCircle = ({ url }) => {
  const wrapRef = useRef(null);

  useEffect(() => {
    const wrap = wrapRef.current;
    const content = wrap.querySelector('.js-magnetic-content');
    const area = wrap.querySelector('.js-magnetic-area');

    function parallaxIt(e, wrap, movement = 1) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const boundingRect = area.getBoundingClientRect(); // Perbaikan di sini
      const relX = e.pageX - boundingRect.left;
      const relY = e.pageY - boundingRect.top;

      gsap.to(content, {
        x: (relX - boundingRect.width / 2) * movement,
        y: (relY - boundingRect.height / 2 - scrollTop) * movement,
        ease: 'power1',
        duration: 0.6,
      });
    }

    function handleMouseMove(e) {
      parallaxIt(e, wrap);
    }

    function handleMouseLeave() {
      gsap.to(content, {
        scale: 1,
        x: 0,
        y: 0,
        ease: 'elastic.out',
        duration: 0.6,
      });
    }

    area.addEventListener('mousemove', handleMouseMove);
    area.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      area.removeEventListener('mousemove', handleMouseMove);
      area.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <>
      {/* <a href={'/' + url}> */}
        <div className="magnetic-wrap" ref={wrapRef}>
          <div className="js-magnetic-area magnetic-size"></div>
          <div className="js-magnetic-content">
            <div className="my-button button-circle">
              <i className="bx bx-right-arrow-alt"></i>
              See More
            </div>
          </div>
        </div>
      {/* </a> */}
    </>
  );
};

export default ButtonCircle;
