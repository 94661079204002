import React from 'react';

import { Link } from 'react-router-dom';

import ButtonCircle from '../../Button/ButtonCircle';

import img1 from '../../../Assets/Image/henbuk_thumbnail.webp';
import img2 from '../../../Assets/Image/sass_thumbnail.webp';
import img3 from '../../../Assets/Image/spotlight_thumbnail.webp';
import img4 from '../../../Assets/Image/noabike_thumbnail.webp';
import img5 from '../../../Assets/Image/digivition_thumbnail.webp';
import img6 from '../../../Assets/Image/exotic_komodo_thumbnail.webp';

const WorkHomePage = () => {

    return (
        <>
            <section className='work section' id='work' data-scroll-section>
                <div className='work__container container'>
                    <div className='pree__titleContainer work__preeTitle'>
                        <div className='pree-line'></div>
                        <pre className="pree-title">Works</pre>
                    </div>
                    <div className='work__titleContainer'>
                        <h1 className='title'>
                            Exploring My Featured <br/> Works
                        </h1>
                        <p>
                            I am passionate about uncovering the best digital innovations for forward-thinking brands looking to push boundaries and drive significant impact.
                        </p>
                    </div>

                    <div className='work__data grid'>
                        
                        <Link to='/works/henbuk' className='work__item'>
                            <div className='work__number'>
                                <span>01</span>
                            </div>
                            <div className='work__imageContainer'>
                                <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                    <picture>
                                        <source srcSet={img1} type="image/webp" />
                                        <img src={img1} alt="Aditya Semara | Henbuk Mockup" loading="lazy" />
                                    </picture>
                                </div>
                            </div>
                            <div className='work__tag'>
                                <span>Brand Identity</span>
                            </div>
                            <div className='work__detail'>
                                <h3 className='work__title'>Henbuk</h3>
                                <div className='work__line'></div>
                            </div>
                        </Link>

                        <Link to='/works/sass-bali-development' className='work__item'>
                            <div className='work__number'>
                                <span>02</span>
                            </div>
                            <div className='work__imageContainer'>
                                <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                    <picture>
                                        <source srcSet={img2} type="image/webp" />
                                        <img src={img2} alt="Aditya Semara | Sass Bali Development Mockup" loading="lazy" />
                                    </picture>
                                </div>
                            </div>
                            <div className='work__tag'>
                                <span>Brand Identity</span>
                            </div>
                            <div className='work__detail'>
                                <h3 className='work__title'>Sass Bali Development</h3>
                                <div className='work__line'></div>
                            </div>
                        </Link>

                        <Link to='/works/spotlight' className='work__item'>
                            <div className='work__number'>
                                <span>03</span>
                            </div>
                            <div className='work__imageContainer'>
                                <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                    <picture>
                                        <source srcSet={img3} type="image/webp" />
                                        <img src={img3} alt="Aditya Semara | Spotlight Mockup" loading="lazy" />
                                    </picture>
                                </div>
                            </div>
                            <div className='work__tag'>
                                <span>Web Development</span>
                            </div>
                            <div className='work__detail'>
                                <h3 className='work__title'>Spotlight</h3>
                                <div className='work__line'></div>
                            </div>
                        </Link>

                        <Link to='/works/noa-bike' className='work__item'>
                            <div className='work__number'>
                                <span>04</span>
                            </div>
                            <div className='work__imageContainer'>
                                <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                    <picture>
                                        <source srcSet={img4} type="image/webp" />
                                        <img src={img4} alt="Aditya Semara | Noa Bike Mockup" loading="lazy" />
                                    </picture>
                                </div>
                            </div>
                            <div className='work__tag'>
                                <span>UI/UX Design, Branding</span>
                            </div>
                            <div className='work__detail'>
                                <h3 className='work__title'>Noa Bike</h3>
                                <div className='work__line'></div>
                            </div>
                        </Link>

                        <Link to='/works/digivition' className='work__item'>
                            <div className='work__number'>
                                <span>05</span>
                            </div>
                            <div className='work__imageContainer'>
                                <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                    <picture>
                                        <source srcSet={img5} type="image/webp" />
                                        <img src={img5} alt="Aditya Semara | Digivition Mockup" loading="lazy" />
                                    </picture>
                                </div>
                            </div>
                            <div className='work__tag'>
                                <span>Website Development</span>
                            </div>
                            <div className='work__detail'>
                                <h3 className='work__title'>Digivition</h3>
                                <div className='work__line'></div>
                            </div>
                        </Link>

                        <Link to='/works/exotic-komodo' className='work__item'>
                            <div className='work__number'>
                                <span>06</span>
                            </div>
                            <div className='work__imageContainer'>
                                <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                    <picture>
                                        <source srcSet={img6} type="image/webp" />
                                        <img src={img6} alt="Aditya Semara | Exotic Komodo Mockup" loading="lazy" />
                                    </picture>
                                </div>
                            </div>
                            <div className='work__tag'>
                                <span>UI/UX Design</span>
                            </div>
                            <div className='work__detail'>
                                <h3 className='work__title'>Exotic Komodo</h3>
                                <div className='work__line'></div>
                            </div>
                        </Link>
                    </div>
                    <div className='work__btn'>
                        <Link to={'/works'}>
                            <ButtonCircle></ButtonCircle>
                        </Link>
                    </div>

                </div>
            </section>
            
        </>
    );
};

export default WorkHomePage;