import React, { useEffect, useRef} from 'react';

const MySkill = () =>{
    return(
        <section className='mySkill section' id='mySkill' data-scroll-section>
            <div className='mySkill__container container'>
                <div className="skill__title">
                    <div className="pree__titleContainer">
                        <div className="pree-line"></div>
                        <span className="pree-title">Skills</span>
                    </div>
                    <h1  className="title">My Skill Set</h1>
                </div>

                <div className='skill__wrapper grid'>
                    <div className='skill__item grid'>
                        <div className='skill__data'>
                            <h2>Front-End Development</h2>
                            <p className='skill__desc'>
                                As a front-end developer, I bring web designs to life, ensuring seamless user experiences through clean and responsive interfaces.
                            </p>
                        </div>
                        <div className='skill__list'>
                            <span>Abilities</span>
                            <ul>
                                <li>HTML/CSS</li>
                                <li>SASS</li>
                                <li>React.js</li>
                                <li>GSAP Animation</li>
                                <li>JQuery</li>
                                <li>Bootstrap</li>
                            </ul>
                        </div>
                    </div>

                    <div className='skill__item grid'>
                        <div className='skill__data'>
                            <h2>Back-End Development</h2>
                            <p className='skill__desc'>
                                In back-end development, I architect robust and scalable solutions, handling server-side logic to empower dynamic and efficient digital experiences.
                            </p>
                        </div>
                        <div className='skill__list'>
                            <span>Abilities</span>
                            <ul>
                                <li>PHP</li>
                                <li>MySQL</li>
                                <li>Laravel</li>
                                <li>C++</li>
                            </ul>
                        </div>
                    </div>

                    <div className='skill__item grid'>
                        <div className='skill__data'>
                            <h2>Digital Design</h2>
                            <p className='skill__desc'>
                                My expertise in digital design transforms concepts into visually captivating experiences, blending creativity and functionality for impactful user interfaces.
                            </p>
                        </div>
                        <div className='skill__list'>
                            <span>Abilities</span>
                            <ul>
                                <li>Brand Identity</li>
                                <li>Logo Design</li>
                                <li>UI/UX Design</li>
                                <li>Wireframes</li>
                                <li>Sitemaps</li>
                                <li>UX research</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MySkill