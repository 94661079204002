import React from 'react';
import transition from '../Animations/transition';

const Contact = ({isLoading}) => {
    console.log(isLoading)
    return (
        <div>
            <h1>Contact Page</h1>
        </div>
    );
}

export default transition(Contact);