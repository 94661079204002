import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer className='footer' id='footer' data-scroll-section>
                <div className='footer__container container'>
                    <div className='footer__data grid'>
                        <div className='footer__contact'>
                            <div className='pree__titleContainer footer__preeTitle'>
                                <div className='pree-line'></div>
                                <pre className="pree-title">Contact</pre>
                            </div>
                            <h1 className='title'>Keep In Touch</h1>
                            
                            <div className='footer__email'>
                                <pre className='email__pre'>Start a conversation</pre>
                                <a href={`mailto:adityasemara.work@gmail.com?subject=Collaboration%20Opportunity&body=${encodeURIComponent("Hello there,\n\nI'm interested in exploring a potential collaboration on a project. Could we discuss further details?\n\nThank you")}`}>adityasemara.work@gmail.com</a>
                            </div>

                        </div>
                        <div className='footer__linkContainer'>
                            <div className='footer__linkItem'>
                                <span>Site Map</span>
                                <ul>
                                    <li>
                                        <Link to='/' className='footer__link'>
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/about' className='footer__link'>
                                            About Me
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/works' className='footer__link'>
                                            Work
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`mailto:adityasemara.work@gmail.com?subject=Collaboration%20Opportunity&body=${encodeURIComponent("Hello there,\n\nI'm interested in exploring a potential collaboration on a project. Could we discuss further details?\n\nThank you")}`} className='footer__link'>
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className='footer__linkItem'>
                                <span>Follow Me</span>
                                <ul>
                                    <li>
                                        <Link to='https://www.instagram.com/iwyn.adityasp/' className='footer__link'>
                                            Instagram
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='https://www.linkedin.com/in/i-wayan-aditya-semara-putra/' className='footer__link'>
                                            LinkedIn
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='https://www.facebook.com/wayanaditya.semara' className='footer__link'>
                                            Facebook
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='footer__copy'>
                        <span>Development By : <a href='https://www.instagram.com/iwyn.adityasp/'>Aditya Semara</a></span>

                        <span>© 2024 Aditya Semara</span>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;