// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import { useEffect, useRef } from "react";
// import LocomotiveScroll from "locomotive-scroll";
// import { ResizeObserver } from "@juggle/resize-observer";
// // import "locomotive-scroll/src/locomotive-scroll.scss";

// gsap.registerPlugin(ScrollTrigger);

// export default function useLocoScroll(start) {
//   useEffect(() => {
//     if (!start) return;
//     let locoScroll = null;

//     const scrollEl = document.querySelector("#main-container");

//     locoScroll = new LocomotiveScroll({
//       el: scrollEl,
//       smooth: true,
//       smartphone: {
//         smooth: false
//         },
//       tablet: {
//           smooth: true
//       },
//       resetNativeScroll: true,
//       smoothMobile: 0,
//       multiplier: 1,
//       // class: "is-reveal",
//     });

//     locoScroll.on("scroll", () => {
//       ScrollTrigger.update();
//     });

//     ScrollTrigger.scrollerProxy(scrollEl, {
//       scrollTop(value) {
//         if (locoScroll) {
//           return arguments.length
//             ? locoScroll.scrollTo(value, 0, 0)
//             : locoScroll.scroll.instance.scroll.y;
//         }
//         return null;
//       },
//       scrollLeft(value) {
//         if (locoScroll) {
//           return arguments.length
//             ? locoScroll.scrollTo(value, 0, 0)
//             : locoScroll.scroll.instance.scroll.x;
//         }
//         return null;
//       },
//     });

//     const lsUpdate = () => {
//       if (locoScroll) {
//         window.onresize = locoScroll.update();
//       }
//     };

//     ScrollTrigger.addEventListener("refresh", lsUpdate);
//     ScrollTrigger.refresh();

//     new ResizeObserver(() => locoScroll.update()).observe(document.querySelector("#main-container"))

//     return () => {
//       if (locoScroll) {
//         ScrollTrigger.removeEventListener("refresh", lsUpdate);
//         locoScroll.destroy();
//         // locoScroll = null;
//         console.log("Kill", locoScroll);
//       }
//     };
//   }, [start]);
// }

// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import { useEffect, useRef } from "react";
// import LocomotiveScroll from "locomotive-scroll";
// import { ResizeObserver } from "@juggle/resize-observer";

// gsap.registerPlugin(ScrollTrigger);

// export default function useLocoScroll(start) {
//   useEffect(() => {
//     if (!start) return;
//     let locoScroll = null;

//     const scrollEl = document.querySelector("#main-container");

//     locoScroll = new LocomotiveScroll({
//       el: scrollEl,
//       smooth: true,
//       smartphone: {
//         smooth: false
//       },
//       tablet: {
//         smooth: true
//       },
//       resetNativeScroll: true,
//       smoothMobile: 0,
//       multiplier: 1,
//       // class: "is-reveal",
//     });

//     locoScroll.on("scroll", () => {
//       ScrollTrigger.update();
//     });

//     ScrollTrigger.scrollerProxy(scrollEl, {
//       scrollTop(value) {
//         if (locoScroll) {
//           return arguments.length
//             ? locoScroll.scrollTo(value, 0, 0)
//             : locoScroll.scroll.instance.scroll.y;
//         }
//         return null;
//       },
//       scrollLeft(value) {
//         if (locoScroll) {
//           return arguments.length
//             ? locoScroll.scrollTo(value, 0, 0)
//             : locoScroll.scroll.instance.scroll.x;
//         }
//         return null;
//       },
//     });

//     const lsUpdate = () => {
//       if (locoScroll) {
//         locoScroll.update();
//       }
//     };

//     ScrollTrigger.addEventListener("refresh", lsUpdate);
//     ScrollTrigger.refresh();

//     const resizeObserver = new ResizeObserver(lsUpdate);
//     resizeObserver.observe(scrollEl);

//     return () => {
//       if (locoScroll) {
//         ScrollTrigger.removeEventListener("refresh", lsUpdate);
//         resizeObserver.unobserve(scrollEl);
//         locoScroll.destroy();
//         locoScroll = null;
//         console.log("Kill", locoScroll);
//       }
//     };
//   }, [start]);
// }


// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import { useEffect } from "react";
// import LocomotiveScroll from "locomotive-scroll";

// gsap.registerPlugin(ScrollTrigger);

// export default function useLocoScroll(start) {
//   useEffect(() => {
//     let locoScroll = null;
//     const scrollEl = document.querySelector("#main-container");

//     if (!scrollEl) {
//       console.error("Element with ID 'main-container' not found.");
//       return;
//     }

//     locoScroll = new LocomotiveScroll({
//       el: scrollEl,
//       inertia: 0.8,
//       smooth: true,
//       smartphone: {
//         smooth: false,
//       },
//       tablet: {
//         smooth: true,
//       },
//       resetNativeScroll: false,
//       smoothMobile: 0,
//       multiplier: 1,
//       class: "is-reveal",
//     });

//     locoScroll.on("scroll", () => {
//       ScrollTrigger.update();
//     });

//     ScrollTrigger.scrollerProxy(scrollEl, {
//       scrollTop(value) {
//         if (locoScroll) {
//           return arguments.length
//             ? locoScroll.scrollTo(value, 0, 0)
//             : locoScroll.scroll.instance.scroll.y;
//         }
//         return null;
//       },
//       scrollLeft(value) {
//         if (locoScroll) {
//           return arguments.length
//             ? locoScroll.scrollTo(value, 0, 0)
//             : locoScroll.scroll.instance.scroll.x;
//         }
//         return null;
//       },
//     });

//     const updateLocoScroll = () => {
//       if (locoScroll) {
//         locoScroll.update();
//       }
//     };

//     const ro = new ResizeObserver(updateLocoScroll);
//     ro.observe(scrollEl);

//     if (start) {
//       updateLocoScroll();
//     }

//     return () => {
//       ro.disconnect();
//       if (locoScroll) {
//         ScrollTrigger.removeEventListener("refresh", updateLocoScroll);
//         locoScroll.destroy();
//         locoScroll = null;
//         console.log("Kill");
//       }
//     };
//   }, [start]);
// }

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import LocomotiveScroll from "locomotive-scroll";

gsap.registerPlugin(ScrollTrigger);

export default function useLocoScroll(start) {
  const locoScrollRef = useRef(null);

  useEffect(() => {
    // if (!start) return;

    const scrollEl = document.querySelector("#main-container");

    locoScrollRef.current = new LocomotiveScroll({
      el: scrollEl,
      smooth: true,
      smartphone: {
        smooth: false,
      },
      tablet: {
        smooth: true,
      },
      resetNativeScroll: true,
      smoothMobile: 0,
      multiplier: 1,
      class: "is-reveal",
    });

    locoScrollRef.current.on("scroll", ScrollTrigger.update);

    ScrollTrigger.scrollerProxy(scrollEl, {
      scrollTop(value) {
        if (locoScrollRef.current) {
          return arguments.length
            ? locoScrollRef.current.scrollTo(value, 0, 0)
            : locoScrollRef.current.scroll.instance.scroll.y;
        }
        return null;
      },
      scrollLeft(value) {
        if (locoScrollRef.current) {
          return arguments.length
            ? locoScrollRef.current.scrollTo(value, 0, 0)
            : locoScrollRef.current.scroll.instance.scroll.x;
        }
        return null;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: scrollEl.style.transform ? "transform" : "fixed",
    });

    const lsUpdate = () => {
      if (locoScrollRef.current) {
        locoScrollRef.current.update();
      }
    };

    ScrollTrigger.addEventListener("refresh", lsUpdate);
    ScrollTrigger.refresh();

    new ResizeObserver(lsUpdate).observe(
      document.querySelector("[data-scroll-container]")
    );

    return () => {
      if (locoScrollRef.current) {
        ScrollTrigger.removeEventListener("refresh", lsUpdate);
        locoScrollRef.current.destroy();
        locoScrollRef.current = null;
      }
    };
  }, [start]);
}






























