import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import img1 from '../../Assets/Image/branding_img.webp'
import img2 from '../../Assets/Image/website_img.webp'
import img3 from '../../Assets/Image/ui_ux_img.webp'
import img4 from '../../Assets/Image/social_img.webp'
import img5 from '../../Assets/Image/print_img.webp'

const MenuHover = () => {

useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c;

    const lerp = (a, b, n) => (1 - n) * a + n * b;

    const clamp = (num, min, max) => (num <= min ? min : num >= max ? max : num);

    const getMousePos = (e) => {
      let posx = 0;
      let posy = 0;
      if (!e) e = window.event;
      if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
      } else if (e.clientX || e.clientY) {
        posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      }

      return { x: posx, y: posy };
    };

    let mouse = { x: 0, y: 0 };
    window.addEventListener('mousemove', (ev) => (mouse = getMousePos(ev)));

    class Cursor {
      constructor(el) {
        this.DOM = { el: el };

        this.renderedStyles = {
          tx: { previous: 0, current: 0, amt: 0.2 },
          ty: { previous: 0, current: 0, amt: 0.2 },
        };

        this.onMouseMoveEv = () => {
          gsap.to(this.DOM.el, { duration: 0.9, ease: 'Power3.easeOut', opacity: 1 });
          requestAnimationFrame(() => this.render());
          window.removeEventListener('mousemove', this.onMouseMoveEv);
        };

        window.addEventListener('mousemove', this.onMouseMoveEv);
      }

      render() {
        for (const key in this.renderedStyles) {
          this.renderedStyles[key].previous = lerp(
            this.renderedStyles[key].previous,
            this.renderedStyles[key].current,
            this.renderedStyles[key].amt
          );
        }

        requestAnimationFrame(() => this.render());
      }
    }

    class Menu {
      constructor(el) {
        this.DOM = { el: el };
        this.DOM.menuItems = this.DOM.el.querySelectorAll('.menu__item');

        this.animatableProperties = {
          tx: { previous: 0, current: 0, amt: 0.08 },
          ty: { previous: 0, current: 0, amt: 0.08 },
          rotation: { previous: 0, current: 0, amt: 0.08 },
        };

        this.menuItems = [];
        [...this.DOM.menuItems].forEach((item, pos) =>
          this.menuItems.push(new MenuItem(item, pos, this.animatableProperties))
        );

        this.showMenuItems();
      }

      showMenuItems() {
        gsap.to(this.menuItems.map((item) => item.DOM.textInner), {
          duration: 1.2,
          ease: 'Expo.easeOut',
          startAt: { y: '100%' },
          y: 0,
          delay: (pos) => pos * 0.06,
        });
      }
    }

    let images;

    let dataImg = Array.from(document.querySelectorAll('[data-img]'));
    let urls = new Array();

    dataImg.forEach((url) => {
      urls.push(url.dataset.img);
    });

    images = Object.entries(urls);

    // console.log(images);

    let mousepos = { x: 0, y: 0 };
    let mousePosCache = mousepos;
    let direction = { x: mousePosCache.x - mousepos.x, y: mousePosCache.y - mousepos.y };
    window.addEventListener('mousemove', (ev) => (mousepos = getMousePos(ev)));

    class MenuItem {
      constructor(el, inMenuPosition, animatableProperties) {
        this.DOM = { el: el };
        this.inMenuPosition = inMenuPosition;
        this.animatableProperties = animatableProperties;
        this.DOM.textInner = this.DOM.el.querySelector('.menu__item-textinner');
        this.layout();
        this.initEvents();
      }

      layout() {
        this.DOM.reveal = document.createElement('div');
        this.DOM.reveal.className = 'hover-reveal';
        this.DOM.revealInner = document.createElement('div');
        this.DOM.revealInner.className = 'hover-reveal__inner';
        this.DOM.revealImage = document.createElement('div');
        this.DOM.revealImage.className = 'hover-reveal__img';
        this.DOM.revealImage.style.backgroundImage = `url(${images[this.inMenuPosition][1]})`;

        this.DOM.revealInner.appendChild(this.DOM.revealImage);
        this.DOM.reveal.appendChild(this.DOM.revealInner);
        this.DOM.el.appendChild(this.DOM.reveal);
      }

      calcBounds() {
        this.bounds = {
          el: this.DOM.el.getBoundingClientRect(),
          reveal: this.DOM.reveal.getBoundingClientRect(),
        };
      }

      initEvents() {
        this.mouseenterFn = (ev) => {
          this.showImage();
          this.firstRAFCycle = true;
          this.loopRender();
        };

        this.mouseleaveFn = () => {
          this.stopRendering();
          this.hideImage();
        };

        this.DOM.el.addEventListener('mouseenter', this.mouseenterFn);
        this.DOM.el.addEventListener('mouseleave', this.mouseleaveFn);
      }

      showImage() {
        gsap.killTweensOf(this.DOM.revealInner);
        gsap.killTweensOf(this.DOM.revealImage);

        this.tl = gsap.timeline({
          onStart: () => {
            this.DOM.reveal.style.opacity =  this.DOM.revealInner.style.opacity = 1;
            gsap.set(this.DOM.el, { zIndex: 120 });
          },
        })
          .to(this.DOM.revealInner, 0.6, {
            ease: 'Expo.easeOut',
            startAt: {
                scale: .6
            },
            scale: 1,
          })
          .to(this.DOM.revealImage, 0.6, {
            ease: 'Expo.easeOut',
            startAt: {
                scale: 1.4
            },
            scale: 1,
          }, 0);
      }

      hideImage() {
        gsap.killTweensOf(this.DOM.revealInner);
        gsap.killTweensOf(this.DOM.revealImage);

        this.tl = gsap.timeline({
          onStart: () => {
            gsap.set(this.DOM.el, { zIndex: 1 });
          },
          onComplete: () => {
            gsap.set(this.DOM.reveal, { opacity: 0 });
          },
        })
          .to(this.DOM.revealInner, 0.4, {
            ease: 'Expo.easeOut',
            scale: 0.6,
            opacity: 0
          })
          .to(this.DOM.revealImage, 0.4, {
            ease: 'Expo.easeOut',
            scale: 1.4
          }, 0);
      }

      loopRender() {
        if (!this.requestId) {
          this.requestId = requestAnimationFrame(() => this.render());
        }
      }

      stopRendering() {
        if (this.requestId) {
          window.cancelAnimationFrame(this.requestId);
          this.requestId = undefined;
        }
      }

      render() {
        this.requestId = undefined;
        if (this.firstRAFCycle) {
          this.calcBounds();
        }

        const mouseDistanceX = clamp(Math.abs(mousePosCache.x - mousepos.x), 0, 100);
        direction = { x: mousePosCache.x - mousepos.x, y: mousePosCache.y - mousepos.y };
        mousePosCache = { x: mousepos.x, y: mousepos.y };

        this.animatableProperties.tx.current =
          Math.abs(mousepos.x - this.bounds.el.left) - this.bounds.reveal.width / 2;
        this.animatableProperties.ty.current =
          Math.abs(mousepos.y - this.bounds.el.top) - this.bounds.reveal.height / 2;
        // new rotation value
        this.animatableProperties.rotation.current = this.firstRAFCycle
          ? 0
          : map(mouseDistanceX, 0, 100, 0, direction.x < 0 ? 60 : -60);

        // set up the interpolated values
        // for the first cycle, both the interpolated values need to be the same so there's no "lerped" animation between the previous and current state
        this.animatableProperties.tx.previous = this.firstRAFCycle
          ? this.animatableProperties.tx.current
          : lerp(
              this.animatableProperties.tx.previous,
              this.animatableProperties.tx.current,
              this.animatableProperties.tx.amt
            );
        this.animatableProperties.ty.previous = this.firstRAFCycle
          ? this.animatableProperties.ty.current
          : lerp(
              this.animatableProperties.ty.previous,
              this.animatableProperties.ty.current,
              this.animatableProperties.ty.amt
            );
        this.animatableProperties.rotation.previous = this.firstRAFCycle
          ? this.animatableProperties.rotation.current
          : lerp(
              this.animatableProperties.rotation.previous,
              this.animatableProperties.rotation.current,
              this.animatableProperties.rotation.amt
            );

        // set styles
        gsap.set(this.DOM.reveal, {
          x: this.animatableProperties.tx.previous,
          y: this.animatableProperties.ty.previous,
          rotation: this.animatableProperties.rotation.previous,
        });

        // loop
        this.firstRAFCycle = false;
        this.loopRender();
      }
    }

    const menuEl = document.querySelector('.menu__hover');
    new Menu(menuEl);
  }, []);


  return (
    <>
      <div className="menu__hoverContainer">
        <div className="menu__hover">
            <a
                className="menu__item"
                data-img={img1}
            >
                <div className='menu__itemTextContainer'>
                    <span className="menu__item-text">
                    <span className="menu__item-textinner">Brand Identity Design</span>
                    </span>
                    <span className="menu__item-sub">01</span>
                </div>
            </a>
            <a
                className="menu__item"
                data-img={img2}
            >
                <div className='menu__itemTextContainer'>
                    <span className="menu__item-text">
                    <span className="menu__item-textinner">Website Development</span>
                    </span>
                    <span className="menu__item-sub">02</span>
                </div>
            </a>
            {/* <br></br> */}
            <a
                className="menu__item"
                data-img={img3}
            >
                <div className='menu__itemTextContainer'>
                    <span className="menu__item-text">
                    <span className="menu__item-textinner">UI/UX Design</span>
                    </span>
                    <span className="menu__item-sub">03</span>
                </div>
            </a>
            <a
                className="menu__item"
                data-img={img4}
            >
                <div className='menu__itemTextContainer'>
                    <span className="menu__item-text">
                        <span className="menu__item-textinner">Social Media Design</span>
                    </span>
                    <span className="menu__item-sub">04</span>
                </div>
            </a>
            <a
                className="menu__item"
                data-img={img5}
            >
                <div className='menu__itemTextContainer'>
                    <span className="menu__item-text">
                        <span className="menu__item-textinner">Printable Design</span>
                    </span>
                    <span className="menu__item-sub">05</span>
                </div>
            </a>
        </div>
      </div>
    </>
  );
};

export default MenuHover;
