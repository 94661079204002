import React, { useEffect, useRef, useState} from 'react';
import {gsap, ScrollTrigger} from 'gsap/src/all';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import ButtonCircle from '../../Button/ButtonCircle';
import useOnScreen from '../../Animations/useOnScreen';

import SplitType from 'split-type';

const About = () => {

    gsap.registerPlugin(ScrollTrigger)

    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen])

    useEffect(() => {
        if (reveal) {

            const myTitle = new SplitType('.about__titleContainer .title');
            const myText = new SplitType('.about__desc p');

            const tl = gsap.timeline({
                scrollTrigger:{
                    trigger: '#about',
                    start: 'top 90%',
                }
            })
            
            .from('.about .pree__titleContainer',{
                x: -100,
                // delay: .2,
                opacity: 0,
                duration: 0.5,
                ease: "power2.out",
            })
            .from('.title .line .word', {
                duration: 0.5,
                y: 150,
                skewY: 15,
                stagger : 0.02,
                ease: "power2.out",
            },'-=0.5')
            .from('.about__desc .line .word', {
                duration: 0.8,
                y: 150,
                skewY: 15,
                stagger : 0.02,
                ease: "power2.out",
            },'-=0.2')
            .from('.about__btn .magnetic-wrap', {
                duration: 0.8,
                scale: 0,
                ease: "power2.out",
                // delay: 0.5
            },'-=0.9')

        }
    }, [reveal]);


    return (
        <>
            <section className={cn('about section', { "is-reveal": reveal })} id='about' ref={ref} data-scroll-section>
                <div className='about__container container'>
                    <div className='about__titleContainer'>
                        <div className={cn('pree__titleContainer', { "is-reveal": reveal }) }>
                            <div className='pree-line'></div>
                            <pre className="pree-title">About Me</pre>
                        </div>
                        <h1 className={cn('title', { "is-reveal": reveal })} ref={ref}>
                            I Empower Brands Through <br/> Creative Technology
                        </h1>
                    </div>
                    <div className='about__data'>
                        <div className='about__desc' ref={ref}>
                            <p>I am passionate about helping businesses establish a strong online presence through strategic planning, creative design, and seamless execution.</p>
                        </div>
                        <div className='about__btn' ref={ref}>
                            <Link to={'/about'}>
                                <ButtonCircle></ButtonCircle>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    );
};

export default About;