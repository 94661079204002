import React from 'react';
import MenuHover from '../../Pages/MenuHover';


const Expertise = () => {
    return (
        <>
            <section className='expertise section' id='expertise'  data-scroll-section >
                <div className='expertise__container container'>
                    <div className='expertise__data'>
                        <div className='pree__titleContainer expertise__preeTitle'>
                            <div className='pree-line'></div>
                            <pre className="pree-title">Expertise</pre>
                        </div>
                        <p className='expertise__desc'>
                            I excel in diverse digital solutions and strategies. My passion lies in delivering innovation and creativity to ensure your online presence grows stronger and achieves business success.
                        </p>
                    </div>

                    <div className='menu__hoverContainer'>
                        <MenuHover></MenuHover>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Expertise;