import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import {gsap, ScrollTrigger} from 'gsap/src/all';
import mockup from '../../../Assets/Image/mockup.webp'

import useOnScreen from '../../Animations/useOnScreen';

import cn from 'classnames';

const Mockup = () => {


    

    const background = useRef(null);
    const introImage = useRef(null);

    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen])

    useLayoutEffect(() => {

        gsap.registerPlugin(ScrollTrigger)

        if (reveal) {

            const tl = gsap.timeline({
                scrollTrigger:{
                    trigger: '#mockup',
                    start: 'top 90%',
                    // scrub: true,
                    // end: "+=500px",
                }
            })
            
            .from(background.current,{
                clipPath: `inset(0 15%)`,
                duration: 0.5,
                ease: "power2.out",
            })
            

        }
    }, [reveal]);

    return (
        <>
            <section className='mockup' id='mockup' data-scroll-section ref={ref}>
                <div className={cn('mockup__container', { "is-reveal": reveal })} ref={background}>
                    <picture>
                        <source srcSet={mockup} type="image/webp" />
                        <img src={mockup} className={cn('mockup__image', { "is-reveal": reveal })} alt="Aditya Semara | Anang Art Shop Mockup" loading="lazy" />
                    </picture>
                </div>
            </section>
        </>
    );
};

export default Mockup;