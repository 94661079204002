import React, { useEffect, useRef} from 'react';
import transition from '../../Animations/transition';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {gsap, ScrollTrigger} from 'gsap/src/all';
import SplitType from 'split-type';

import useLocoScroll from '../../Animations/useLocoScroll';
import Footer from '../../Sections/Footer/Footer';

import img1 from '../../../Assets/Image/portfolio/digivition/digivition_hero.webp'
import img2 from '../../../Assets/Image/portfolio/digivition/digivition_1.webp'
import img3 from '../../../Assets/Image/portfolio/digivition/digivition_2.webp'

const Digivition = ({isLoading}) => {

    const containerRef = useRef(null)
    const ref = useRef(null)

    
    // const startLocoScroll = true;

    useLocoScroll(!isLoading);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        if(isLoading === false){
            const myTitle = new SplitType('.projectHeader__title');

            const timeline_home = gsap.timeline({
                // paused: true
            });
            timeline_home.from('.projectHeader__title .line .word', {
                duration: 0.8,
                skewY: 15,
                y: 150,
                stagger : 0.1,
                ease: "power2.out",
                delay: 5.5,
            })
            .from('.pree__titleContainer .pree-line',{
                width: 0,
                duration: 0.5,
                stagger : 0.2,
                ease: "power2.out",
            }, '-=1.7')
            .from('.pree__titleContainer .pree-title',{
                duration: 0.8,
                skewY: 15,
                y: 150,
                stagger : 0.1,
                ease: "power2.out",
            }, '-=1.7')
        }
        else{
            const myTitle = new SplitType('.projectHeader__title');

            const timeline_home = gsap.timeline({
                // paused: true
            });
            timeline_home.from('.projectHeader__title .line .word', {
                duration: 0.8,
                skewY: 15,
                y: 150,
                stagger : 0.1,
                ease: "power2.out",
            })
            .from('.pree__titleContainer .pree-line',{
                width: 0,
                duration: 0.5,
                stagger : 0.2,
                ease: "power2.out",
            }, '-=1.7')
            .from('.pree__titleContainer .pree-title',{
                duration: 0.8,
                skewY: 15,
                y: 150,
                stagger : 0.1,
                ease: "power2.out",
            }, '-=1.7')
        }
        
        

        gsap.set(".cursor-follower", {xPercent: 50, yPercent: 50});

        const ball = document.querySelector(".cursor-follower");
        const pos = { x: window.innerWidth / 2.5, y: 150 };
        const mouse = { x: pos.x, y: pos.y };
        const speed = 0.03;

        const xSet = gsap.quickSetter(ball, "x", "px");
        const ySet = gsap.quickSetter(ball, "y", "px");

        window.addEventListener("mousemove", e => {    
        mouse.x = e.x;
        mouse.y = e.y;  
        });

        gsap.ticker.add(() => {
        
            // adjust speed for higher refresh monitors
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
            
            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });
    },[])


    useEffect(() =>{

        let items = gsap.utils.toArray(".next__project"),
        cursor = document.querySelector(".mycircleicon"),
        xTo = gsap.quickTo(cursor, "x", {duration: 0.3, ease: "power3"}),
        yTo = gsap.quickTo(cursor, "y", {duration: 0.3, ease: "power3"});

        gsap.set(cursor, {autoAlpha: 1, scale: 0});

        window.addEventListener("mousemove", (e) => {
            xTo(e.pageX);
            yTo(e.pageY);
        });

        items.forEach((item) => {
            item.addEventListener("mouseenter", () => {
                gsap.to(cursor, { scale: 1, duration: 0.2 });
                item.style.cursor = "move"; // Ubah gaya cursor menjadi "move"
            });
            item.addEventListener("mouseleave", () => {
                gsap.to(cursor, { scale: 0, duration: 0.2 });
                item.style.cursor = "auto"; // Kembalikan gaya cursor ke "auto"
            });
        });


    }, [])
    

    return (
        <>
            <Helmet>
                <title>Aditya Semara | Work Detail - Digivition</title>
                <meta name="description" content="Aditya Semara - Creative Website Developer and Brand Identity Designer"/>
                <meta name="keywords" content="Aditya Semara, Website Developer, Brand Identity Designer, Creative Design"/>
                <meta name="author" content="Aditya Semara"/>
            </Helmet>

            <div data-load-container>
                <main id='main-container' data-scroll-container ref={containerRef}>

                    <section className='project__header' id='projectDetail' ref={ref} data-scroll-section>
                        <div className='projectHeader__container'>
                            <div className='projectHeader__img digivition' ref={ref} data-scroll data-scroll-speed="-5"></div>
                            <div className='projectHeader__overlay'></div>
                            <div className='projectHeader__data' data-scroll data-scroll-speed="-9">
                                <div className='pree__titleContainer' ref={ref}>
                                    <div className='pree-line'></div>
                                    <pre className="pree-title">Works</pre>
                                    <span className="pree-title"><i className='bx bx-chevron-right'></i></span>
                                    <span className="pree-title">Digivition</span>
                                </div>
                                <h2 className='projectHeader__title' ref={ref}>Modern and Innovative Digital Wedding Invitations</h2>
                            </div>
                        </div>
                    </section>
                    <section className='project__overview section' id='projectOverview' data-scroll-section>
                        <div className='projectOverview__container container'>
                            <div className='projectOverview__data grid'>
                                <div className='projectOverview__title grid'>
                                    <h1>Digivition</h1>
                                    <p>Digivition is a company specializing in the creation of digital invitation websites. They offer a modern and innovative solution for designing digital invitations for weddings. With Digivition's platform, customers can easily create elegant, engaging, and interactive invitations that can be shared with guests through electronic links.</p>
                                </div>
                                <div className='projectOverview__item grid'>
                                    <div className='projectOverview__info'>
                                        <span>Project Scope</span>
                                        <p className='projectOverview__serviceDesc'>Website design, Website Development</p>

                                        <div className='project__url'>
                                            <span>Url</span>
                                            <a href='https://digivition.com/' className='btn__visit'>Live Preview <i className='bx bx-right-arrow-alt' ></i></a>
                                        </div>
                                    </div>
                                    <div className='projectOverview__grup'>
                                        <div className='projectOverview__info'>
                                            <span>Year</span>
                                            <p>2023</p>
                                        </div>
                                        <div className='projectOverview__info'>
                                            <span>Role</span>
                                            <p>Website Developer</p>
                                        </div>
                                        <div className='projectOverview__info'>
                                            <span>Technology</span>
                                            <p>Reac.js, SASS</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='project__image section' id='project__image' data-scroll-section>
                        <div className='projectImage__container container'>
                            <div className='projectImage__data grid'>
                                <img className='two_column' src={img1} alt='Aditya Semara | Digivition Mockup' />
                                <img src={img2} alt='Aditya Semara | Digivition Mockup' />
                                <img src={img3} alt='Aditya Semara | Digivition Mockup' />
                            </div>
                        </div>
                    </section>
                    <section className='project__header next__project' id='projectDetail' data-scroll-section>
                        <Link to='/works/exotic-komodo'>
                            <div className='projectHeader__container'>
                                <div className='projectHeader__img exotic__komodo' data-scroll data-scroll-speed="-5"></div>
                                <div className='projectHeader__overlay'></div>
                                <div className='projectHeader__data' data-scroll data-scroll-speed="-3">
                                    <div className='pree__titleContainer'>
                                        <div className='pree-line'></div>
                                        <pre className="pree-title">Next Project</pre>
                                        <span className="pree-title"><i className='bx bx-chevron-right'></i></span>
                                        <span className="pree-title">Exotic Komodo</span>
                                    </div>
                                    <h2 className='projectHeader__title'>Exotic Komodo Hotel in Labuan Bajo.</h2>
                                </div>
                            </div>
                        </Link>
                    </section>
                    

                    <div className="mycircleicon next"></div>

                    <div className="cursor-follower" >
                        <div className='blobs1'></div>
                        <div className='blobs2'></div>
                        <div className='blobs3'></div>
                    </div>
                    <Footer/>
                </main>
                
            </div>
        </>
    );
}

export default transition(Digivition);