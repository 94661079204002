import React, { useEffect, useRef} from 'react';
import transition from '../Animations/transition';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {gsap, ScrollTrigger} from 'gsap/src/all';
import SplitType from 'split-type';

import ButtonCircle from '../Button/ButtonCircle';
import useLocoScroll from '../Animations/useLocoScroll';
import Footer from '../Sections/Footer/Footer';
import CallToAction from '../Sections/CallToAction/CallToAction';

// swipper
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { FreeMode, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';

import img1 from '../../Assets/Image/branding_img.webp'
import img2 from '../../Assets/Image/website_img.webp'
import img3 from '../../Assets/Image/ui_ux_img.webp'
import img4 from '../../Assets/Image/social_img.webp'
import img5 from '../../Assets/Image/print_img.webp'

import tilesImg1 from '../../Assets/Image/portfolio/digivition/digivition_1.webp'
import tilesImg2 from '../../Assets/Image/portfolio/digivition/digivition_hero.webp'
import tilesImg3 from '../../Assets/Image/portfolio/digivition/digivition_2.webp'
import tilesImg4 from '../../Assets/Image/portfolio/exotic-komodo/exotic_1.webp'
import tilesImg5 from '../../Assets/Image/portfolio/exotic-komodo/exotic_komodo_hero.webp'
import tilesImg6 from '../../Assets/Image/portfolio/exotic-komodo/exotic_2.webp'
import tilesImg7 from '../../Assets/Image/portfolio/henbuk/henbuk_1.webp'
import tilesImg8 from '../../Assets/Image/portfolio/henbuk/henbuk_2.webp'
import tilesImg9 from '../../Assets/Image/portfolio/henbuk/henbuk_hero.webp'
import tilesImg10 from '../../Assets/Image/portfolio/henbuk/henbuk_3.webp'
import tilesImg11 from '../../Assets/Image/portfolio/henbuk/henbuk_4.webp'
import tilesImg12 from '../../Assets/Image/portfolio/henbuk/henbuk_5.webp'
import tilesImg13 from '../../Assets/Image/portfolio/henbuk/henbuk_6.webp'
import tilesImg14 from '../../Assets/Image/portfolio/noa-bike/noabike_1.webp'
import tilesImg15 from '../../Assets/Image/portfolio/noa-bike/noabike_2.webp'
import tilesImg16 from '../../Assets/Image/portfolio/noa-bike/noabike_3.webp'
import tilesImg17 from '../../Assets/Image/portfolio/noa-bike/noabike_4.webp'
import tilesImg18 from '../../Assets/Image/portfolio/noa-bike/noabike_5.webp'
import tilesImg19 from '../../Assets/Image/portfolio/noa-bike/noabike_6.webp'
import tilesImg20 from '../../Assets/Image/portfolio/noa-bike/noabike_hero.webp'
import tilesImg21 from '../../Assets/Image/portfolio/sass-bali/sass_hero.webp'
import tilesImg22 from '../../Assets/Image/portfolio/sass-bali/sass_2.webp'
import tilesImg23 from '../../Assets/Image/portfolio/sass-bali/sass_1.webp'
import tilesImg24 from '../../Assets/Image/portfolio/sass-bali/sass_3.webp'
import tilesImg25 from '../../Assets/Image/portfolio/sass-bali/sass_4.webp'
import tilesImg26 from '../../Assets/Image/portfolio/spotlight/spotlight_1.webp'
import tilesImg27 from '../../Assets/Image/portfolio/spotlight/spotlight_2.webp'
import tilesImg28 from '../../Assets/Image/portfolio/spotlight/spotlight_hero.webp'
import tilesImg29 from '../../Assets/Image/mockup.webp'

const About = ({isLoading}) => {

    const containerRef = useRef(null)
    const ref = useRef(null)

    
    // const startLocoScroll = true;

    useLocoScroll(!isLoading);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {

        const myTitle = new SplitType('.aboutHeader__titleContainer h1');
        const myDesc = new SplitType('.aboutHeader__desc p', { types: 'words, chars' });

        if(isLoading === false){
            const timeline_home = gsap.timeline({
                // paused: true
            });
            timeline_home.from('.aboutHeader__titleContainer .line .word', {
                duration: 0.8,
                skewY: 15,
                y: 200,
                stagger : 0.08,
                ease: "power2.out",
                delay: 5.5,
            })
            .from('.pree__titleContainer',{
                x: -100,
                // delay: .2,
                opacity: 0,
                duration: 0.5,
                ease: "power2.out",
            }, '-=1')
            .from('.aboutHeader__desc p .word .char',{
                duration: 0.5,
                skewY: 15,
                y: 100,
                opacity : 0,
                stagger : 0.003,
                ease: "power2.out",
            }, '-=1')
        }
        else{
            const timeline_home = gsap.timeline({
                // paused: true
            });
            timeline_home.from('.aboutHeader__titleContainer .line .word', {
                duration: 0.8,
                skewY: 15,
                y: 200,
                stagger : 0.08,
                ease: "power2.out",
            })
            .from('.pree__titleContainer',{
                x: -100,
                // delay: .2,
                opacity: 0,
                duration: 0.5,
                ease: "power2.out",
            }, '-=1')
            .from('.aboutHeader__desc p .word .char',{
                duration: 0.5,
                skewY: 15,
                y: 100,
                opacity : 0,
                stagger : 0.003,
                ease: "power2.out",
            }, '-=1')
        }
        
        

        gsap.set(".cursor-follower", {xPercent: 50, yPercent: 50});

        const ball = document.querySelector(".cursor-follower");
        const pos = { x: window.innerWidth / 2.5, y: 150 };
        const mouse = { x: pos.x, y: pos.y };
        const speed = 0.03;

        const xSet = gsap.quickSetter(ball, "x", "px");
        const ySet = gsap.quickSetter(ball, "y", "px");

        window.addEventListener("mousemove", e => {    
        mouse.x = e.x;
        mouse.y = e.y;  
        });

        gsap.ticker.add(() => {
        
            // adjust speed for higher refresh monitors
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
            
            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });
    },[])


    useEffect(() =>{

        let items = gsap.utils.toArray(".slider__item"),
        cursor = document.querySelector(".mycircleicon"),
        xTo = gsap.quickTo(cursor, "x", {duration: 0.3, ease: "power3"}),
        yTo = gsap.quickTo(cursor, "y", {duration: 0.3, ease: "power3"});

        gsap.set(cursor, {autoAlpha: 1, scale: 0});

        window.addEventListener("mousemove", (e) => {
            xTo(e.pageX);
            yTo(e.pageY);
        });

        items.forEach((item) => {
            item.addEventListener("mouseenter", () => {
                gsap.to(cursor, { scale: 1, duration: 0.2 });
                item.style.cursor = "move"; // Ubah gaya cursor menjadi "move"
            });
            item.addEventListener("mouseleave", () => {
                gsap.to(cursor, { scale: 0, duration: 0.2 });
                item.style.cursor = "auto"; // Kembalikan gaya cursor ke "auto"
            });
        });


        // Optimize image loading by lazy loading
        const imageElements = document.querySelectorAll('.tiles__line-img');
        imageElements.forEach((image) => {
            image.setAttribute('loading', 'lazy');
        });

    }, [])
    

    return (
        <>
            <Helmet>
                <title>About Aditya Semara | Creative Website Developer and Brand Identity Designer</title>
                <meta name="description" content="Aditya Semara - Creative Website Developer and Brand Identity Designer"/>
                <meta name="keywords" content="Aditya Semara, Website Developer, Brand Identity Designer, Creative Design"/>
                <meta name="author" content="Aditya Semara"/>
            </Helmet>

            <div data-load-container>
                <main id='main-container' data-scroll-container ref={containerRef}>
                    
                    <section className='aboutHeader' id='aboutSection' data-scroll-section>
                        <div className='aboutHeader__container container'>
                            <div className='aboutHeader__titleContainer' ref={ref}>
                                <div className='pree__titleContainer'>
                                    <div className='pree-line'></div>
                                    <pre className="pree-title">About Me</pre>
                                </div>
                                <h1 className='title' ref={ref}>
                                    +3 Years Experience In Website Development and Brand Identity Industry
                                </h1>
                            </div>
                            <div className='aboutHeader__desc'>
                                <p ref={ref}>
                                    With over three years of expertise in the fields of web development and brand identity design, I bring valuable experience to the industry. My proficiency extends across crafting engaging and functional websites, as well as creating impactful brand identities that resonate with target audiences.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className="tiles tiles--rotated" id="grid2" data-scroll-section>
                        <div className="tiles__wrap">
                            <div className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg1})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg10})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg29})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg4})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg19})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg16})` }}></div>
                            </div>
                            <div className="tiles__line" data-scroll data-scroll-speed="-1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg17})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg28})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg3})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg6})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg2})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg12})` }}></div>
                            </div>
                            <div className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg13})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg8})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg15})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg7})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg17})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg20})` }}></div>
                            </div>
                            <div className="tiles__line" data-scroll data-scroll-speed="-1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg21})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg16})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg21})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg27})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg23})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg3})` }}></div>
                            </div>
                            <div className="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg25})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg11})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg9})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg14})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg29})` }}></div>
                                <div className="tiles__line-img" style={{ backgroundImage: `url(${tilesImg1})` }}></div>
                            </div>
                        </div>
                    </section>

                    <section className='about__expertise section' id='about__expertise' data-scroll-section>
                        <div className='aboutExpertise__data container'>
                            <div className='aboutExpertise__titleContainer'>
                                <div className='pree__titleContainer'>
                                    <div className='pree-line'></div>
                                    <pre className="pree-title">Expertise</pre>
                                </div>
                                <h1 className='title'>
                                    Mastery in Diverse Digital Solutions <br/> and Strategies.
                                </h1>
                            </div>
                            <div className='aboutExpertise__content'>
                                <div className='desc'>
                                    <p>Drawing from my expertise as a web developer and brand identity designer, I provide an insider's view on my mastery of varied digital solutions and strategies. Through years of experience, I specialize in creating dynamic websites and developing compelling brand identities tailored to individual client requirements.</p>
                                </div>
                                <div className='aboutExpertise__btn'>
                                    <Link to={'/'}>
                                        <ButtonCircle/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='aboutExpertise__slider container'>
                            <Swiper

                            slidesPerView={4}
                            spaceBetween={30}
                            freeMode={true}
                            breakpoints={{
                                360:{
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                640: {
                                  slidesPerView: 3,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 3,
                                  spaceBetween: 30,
                                },
                                868: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                  }
                              }}
                            modules={[FreeMode]}
                            className="mySwiper"
                          >
                                <SwiperSlide>
                                    <Link to={'/'}>
                                        <div className='slider__item'>
                                        <picture>
                                            <source srcSet={img1} type="image/webp" />
                                            <img src={img1} alt="Brand Identity Design" loading="lazy" />
                                        </picture>

                                            <h3>Brand Identity Design <sup>01</sup></h3>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider__item'>
                                        <picture>
                                            <source srcSet={img2} type="image/webp" />
                                            <img src={img2} alt="Website Development" loading="lazy" />
                                        </picture>
                                        <h3>Website Development <sup>02</sup></h3>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider__item'>
                                        <picture>
                                            <source srcSet={img3} type="image/webp" />
                                            <img src={img3} alt="UI/UX Design" loading="lazy" />
                                        </picture>
                                        <h3>UI/UX Design <sup>03</sup></h3>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider__item'>
                                        <picture>
                                            <source srcSet={img4} type="image/webp" />
                                            <img src={img4} alt="Social Media Design" loading="lazy" />
                                        </picture>
                                        <h3>Social Media Design <sup>04</sup></h3>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider__item'>
                                        <picture>
                                            <source srcSet={img5} type="image/webp" />
                                            <img src={img5} alt="Printable Design" loading="lazy" />
                                        </picture>
                                        <h3>Printable Design <sup>05</sup></h3>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </section>

                    <section className='values section' id='values' data-scroll-section>
                        <div className='values__container container'>
                            <div className='values__titleContainer'>
                                <div className='pree__titleContainer'>
                                    <div className='pree-line'></div>
                                    <pre className="pree-title">Core Value</pre>
                                </div>
                                <h1 className='title'>
                                    In Everything I Do, the Core Principle is Enhancing Business Excellence.
                                </h1>
                            </div>
                            <div className='values__data'>
                                <div className='values__items'>
                                    <div className='pree__titleContainer'>
                                        <div className='pree-line'></div>
                                        <pre className="pree-title">Quality</pre>
                                    </div>
                                    <div className='valuesItem__title'>
                                        <h3>I Never compromise on quality.</h3>
                                    </div>
                                    <div className='values__desc'>
                                        <p>I aim to align my brand solely with the highest quality standards. I produce top-notch products, offer valuable advice, and selectively engage with exceptional individuals. Those in search of excellence choose us for a distinct reason.</p>
                                    </div>  
                                </div>
                                <div className='values__items'>
                                    <div className='pree__titleContainer'>
                                        <div className='pree-line'></div>
                                        <pre className="pree-title">Experience</pre>
                                    </div>
                                    <div className='valuesItem__title'>
                                        <h3>Everything revolves around the experience.</h3>
                                    </div>
                                    <div className='values__desc'>
                                        <p>My focus has always been to craft enjoyable, intuitive, engaging, and remarkable experiences for people. The experiences I create are what I genuinely value, setting me apart from everyone else.</p>
                                    </div>  
                                </div>
                                <div className='values__items'>
                                    <div className='pree__titleContainer'>
                                        <div className='pree-line'></div>
                                        <pre className="pree-title">All Out</pre>
                                    </div>
                                    <div className='valuesItem__title'>
                                        <h3>Commit to doing things once and doing them right.</h3>
                                    </div>
                                    <div className='values__desc'>
                                        <p>I don’t believe in doing anything halfway. I approach everything with care, expertise, and effort, ensuring that we only follow the right path. While shortcuts may be tempting, we never cut corners in our journey, ever.</p>
                                    </div>  
                                </div>
                                <div className='values__items'>
                                    <div className='pree__titleContainer'>
                                        <div className='pree-line'></div>
                                        <pre className="pree-title">Functional</pre>
                                    </div>
                                    <div className='valuesItem__title'>
                                        <h3>Form and function are interdependent.</h3>
                                    </div>
                                    <div className='values__desc'>
                                        <p>What's the point of function without form or form without function? I firmly believe in the symbiotic relationship between the two. For me, a balance of exquisite form and powerful function is the key formula for success.</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </section>

                    <CallToAction/>

                    <div className="mycircleicon drag"></div>

                    <div className="cursor-follower" >
                        <div className='blobs1'></div>
                        <div className='blobs2'></div>
                        <div className='blobs3'></div>
                    </div>
                    <Footer/>
                </main>
                
            </div>
        </>
    );
}

export default transition(About);