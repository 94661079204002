import React, { useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import transition from '../Animations/transition';

import {gsap, ScrollTrigger} from 'gsap/src/all';
import SplitType from 'split-type';

import Button from '../Button/Button';

import hero1 from '../../Assets/Image/hero_1.webp';
import hero2 from '../../Assets/Image/hero_2.webp';
import hero3 from '../../Assets/Image/hero_3.webp';


import useLocoScroll from '../Animations/useLocoScroll';

import About from '../Sections/About/About';
import Footer from '../Sections/Footer/Footer';
import CallToAction from '../Sections/CallToAction/CallToAction';
import WorkHomePage from '../Sections/Works/WorkHomePage';
import Mockup from '../Sections/Mockup/Mockup';
import Expertise from '../Sections/Expertise/Expertise';
import MySkill from '../Sections/MySkill/MySkill';




const Home = ({isLoading}) => {

    const containerRef = useRef(null)
    const ref = useRef(null)

    
    // const startLocoScroll = true;

    useLocoScroll(!isLoading);

    gsap.registerPlugin(ScrollTrigger);


    useEffect(() => {
        const myTitle = new SplitType('.header__titleLine h1');
        const timeline_home = gsap.timeline({});

        if (!isLoading) {
        timeline_home
            .from('.header__title .line .word', {
            duration: 0.8,
            skewY: 15,
            y: 150,
            stagger: 0.2,
            ease: 'power2.out',
            delay: 5.5,
            })
            .from('.header__btnContainer .header__item', {
            scale: 0,
            duration: 0.5,
            stagger: 0.2,
            ease: 'power2.out',
            }, '-=0.4')
            .from('.header__imageContainer .header__imageItems', {
            clipPath: 'inset(0 0 100% 0)',
            duration: 2.5,
            stagger: 0.2,
            ease: 'power2.out',
            }, '-=0.2');
        } else {
        // Handle loading state
        timeline_home
            .from('.header__title .line .word', {
            duration: 0.8,
            skewY: 15,
            y: 150,
            stagger: 0.2,
            ease: 'power2.out',
            // delay: 10.5,
            })
            .from('.header__btnContainer .header__item', {
            scale: 0,
            duration: 0.5,
            stagger: 0.2,
            ease: 'power2.out',
            }, '-=0.4')
            .from('.header__imageContainer .header__imageItems', {
            clipPath: 'inset(0 0 100% 0)',
            duration: 2.5,
            stagger: 0.2,
            ease: 'power2.out',
            }, '-=0.2');
        }
        
        

        gsap.set(".cursor-follower", {xPercent: 50, yPercent: 50});

        const ball = document.querySelector(".cursor-follower");
        const pos = { x: window.innerWidth / 2.5, y: 150 };
        const mouse = { x: pos.x, y: pos.y };
        const speed = 0.03;

        const xSet = gsap.quickSetter(ball, "x", "px");
        const ySet = gsap.quickSetter(ball, "y", "px");

        window.addEventListener("mousemove", e => {    
        mouse.x = e.x;
        mouse.y = e.y;  
        });

        gsap.ticker.add(() => {
        
            // adjust speed for higher refresh monitors
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
            
            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });

        // Optimize image loading by lazy loading
        const imageElements = document.querySelectorAll('.header__image');
        imageElements.forEach((image) => {
            image.setAttribute('loading', 'lazy');
        });

    },[])


    useEffect(() =>{

        let items = gsap.utils.toArray(".work__item"),
        cursor = document.querySelector(".mycircleicon"),
        xTo = gsap.quickTo(cursor, "x", {duration: 0.3, ease: "power3"}),
        yTo = gsap.quickTo(cursor, "y", {duration: 0.3, ease: "power3"});

        gsap.set(cursor, {autoAlpha: 1, scale: 0});

        window.addEventListener("mousemove", (e) => {
            xTo(e.pageX);
            yTo(e.pageY);
        });

        items.forEach((item) => {
            item.addEventListener("mouseenter", () => {
                gsap.to(cursor, {scale: 1, duration: 0.2, })
            });
            item.addEventListener("mouseleave", () => {
                gsap.to(cursor, {scale: 0, duration: 0.2,});
            });
        });


    }, [])



    return (
        <>
        <Helmet>
            <title>Aditya Semara | Creative Website Developer and Brand Identity Designer</title>
            <meta name="description" content="Aditya Semara - Creative Website Developer and Brand Identity Designer"/>
            <meta name="keywords" content="Aditya Semara, Website Developer, Brand Identity Designer, Creative Design"/>
            <meta name="author" content="Aditya Semara"/>
        </Helmet>


            <div data-load-container> 
                <main id='main-container' data-scroll-container ref={containerRef}>
                    <section className='header' id='header' data-scroll-section>
                        <div className='header__container container'>
                            <div className='header__data'>
                                <div className='header__titleContainer'>
                                    <pre className='pre__title'>
                                        Crafting Digital Solutions
                                    </pre>
                                    <div className='header__titleLine'>
                                        <h1 className='header__title'>Website Developer  </h1>
                                        <h1 className='header__title'>& Brand Identity </h1>
                                        <h1 className='header__title'>Designer </h1>
                                    </div>
                                </div>
                            </div>
                            <div className='header__btnContainer'>
                                <div className='header__item scroll__down' ref={ref}>
                                    <svg className='circle__text' width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M67.3536 45.1417L66.3112 44.8815L67.2503 41.1522L68.2927 41.4124L67.3536 45.1417Z" fill="white"/>
                                        <path d="M61.4277 49.1161C61.639 48.7281 61.8966 48.4134 62.2008 48.1718C62.502 47.9356 62.8273 47.7948 63.1765 47.7495C63.5258 47.7042 63.8726 47.7711 64.2171 47.9501L63.5662 49.1459C63.328 49.038 63.1031 49.0198 62.8915 49.0913C62.6771 49.1681 62.5004 49.334 62.3615 49.5891C62.2198 49.8495 62.1698 50.0873 62.2115 50.3023C62.2586 50.5203 62.3836 50.684 62.5864 50.7935C62.7519 50.8828 62.9155 50.9057 63.0772 50.8624C63.2389 50.819 63.3911 50.7361 63.5337 50.6137C63.6788 50.4994 63.861 50.3296 64.0804 50.1042C64.3839 49.8004 64.6485 49.5616 64.8744 49.3878C65.1026 49.2222 65.3657 49.1132 65.6636 49.0608C65.9639 49.0166 66.2902 49.0896 66.6425 49.2797C66.9734 49.4583 67.2168 49.6963 67.3726 49.9935C67.5285 50.2908 67.5895 50.6228 67.5555 50.9896C67.527 51.3592 67.4085 51.7354 67.2002 52.118C66.8877 52.692 66.4931 53.0807 66.0164 53.2841C65.5421 53.4957 65.0439 53.4847 64.5219 53.2511L65.1903 52.0234C65.3882 52.1165 65.5905 52.1191 65.7972 52.0312C66.0063 51.9516 66.1788 51.7868 66.3148 51.537C66.4335 51.3191 66.4729 51.1135 66.4331 50.9201C66.3905 50.7321 66.2624 50.5805 66.0489 50.4652C65.8994 50.3846 65.7479 50.3647 65.5944 50.4056C65.4434 50.4548 65.2968 50.5338 65.1546 50.6427C65.0149 50.7598 64.8327 50.9297 64.6079 51.1522C64.3044 51.456 64.0371 51.6933 63.806 51.8642C63.5748 52.0351 63.3076 52.1454 63.0044 52.1949C62.7012 52.2444 62.3761 52.1755 62.0292 51.9883C61.7303 51.827 61.4947 51.6001 61.3223 51.3077C61.15 51.0153 61.0675 50.6785 61.0747 50.2974C61.0873 49.9191 61.205 49.5253 61.4277 49.1161Z" fill="white"/>
                                        <path d="M62.103 53.1017C62.5114 53.4256 62.8014 53.7909 62.9729 54.1974C63.1492 54.6077 63.2032 55.0254 63.1348 55.4504C63.0712 55.8791 62.8881 56.2826 62.5855 56.6609C62.1959 57.1479 61.7508 57.4519 61.2504 57.5728C60.751 57.7023 60.237 57.6462 59.7083 57.4047L60.5652 56.3337C60.7815 56.4126 60.992 56.4211 61.1967 56.3593C61.4024 56.306 61.5846 56.1801 61.7435 55.9815C61.9705 55.6978 62.0457 55.3903 61.9692 55.0591C61.8974 54.7317 61.6715 54.4173 61.2916 54.1159C60.9164 53.8184 60.5582 53.6695 60.2168 53.6692C59.8802 53.6728 59.5984 53.8164 59.3715 54.1001C59.0499 54.502 59.0204 54.9075 59.283 55.3167L58.4261 56.3877C58.0858 55.94 57.9183 55.4555 57.9239 54.9344C57.9294 54.4132 58.1251 53.9115 58.511 53.4292C58.8136 53.0509 59.1653 52.7811 59.5661 52.6198C59.9716 52.4622 60.3925 52.4212 60.8288 52.4968C61.2699 52.5761 61.6946 52.7778 62.103 53.1017Z" fill="white"/>
                                        <path d="M58.4239 59.8195C58.4847 60.1257 58.4706 60.4224 58.3817 60.7098C58.2884 61.0013 58.1168 61.2618 57.8669 61.4914L56.9589 60.512L57.2065 60.2844C57.5009 60.0138 57.6602 59.7399 57.6842 59.4628C57.7037 59.1898 57.555 58.8823 57.2379 58.5402L55.533 56.7012L56.4698 55.8402L59.892 59.5315L58.9551 60.3925L58.4239 59.8195Z" fill="white"/>
                                        <path d="M52.0588 59.3151C52.4617 59.0451 52.8837 58.8899 53.3247 58.8494C53.7691 58.814 54.1947 58.8968 54.6014 59.0979C55.003 59.3024 55.3479 59.6178 55.6361 60.0441C55.9242 60.4704 56.0852 60.909 56.1192 61.3599C56.1481 61.8142 56.0581 62.2387 55.8494 62.6336C55.644 63.0336 55.3398 63.3685 54.9368 63.6385C54.5339 63.9085 54.1076 64.0629 53.6582 64.1017C53.2122 64.1456 52.7858 64.0669 52.3791 63.8658C51.9673 63.6681 51.6174 63.356 51.3293 62.9297C51.0412 62.5034 50.8852 62.0615 50.8613 61.6038C50.8324 61.1496 50.9232 60.7208 51.1336 60.3175C51.3424 59.9226 51.6508 59.5885 52.0588 59.3151ZM52.6791 60.2329C52.4876 60.3611 52.3393 60.5261 52.2341 60.7277C52.1273 60.9378 52.0862 61.1693 52.1108 61.4224C52.1355 61.6755 52.2377 61.9349 52.4173 62.2007C52.6851 62.5969 52.996 62.8295 53.3499 62.8984C53.7022 62.9757 54.0294 62.9131 54.3316 62.7106C54.6339 62.5081 54.8154 62.2298 54.8761 61.8758C54.9353 61.5301 54.8309 61.1592 54.5631 60.763C54.2954 60.3668 53.9904 60.1267 53.6482 60.0426C53.3043 59.967 52.9813 60.0304 52.6791 60.2329Z" fill="white"/>
                                        <path d="M51.0466 67.669L48.079 61.6444L49.2237 61.0854L52.1913 67.11L51.0466 67.669Z" fill="white"/>
                                        <path d="M48.1198 68.8747L45.7049 62.6101L46.8953 62.1552L49.3101 68.4198L48.1198 68.8747Z" fill="white"/>
                                        <path d="M43.2832 66.0697C43.3595 66.5718 43.3281 67.032 43.189 67.4503C43.044 67.8696 42.8133 68.2101 42.4969 68.4717C42.1805 68.7334 41.8092 68.8963 41.383 68.9605C41.0588 69.0094 40.7388 68.9842 40.4228 68.8851C40.1078 68.792 39.8455 68.6419 39.636 68.4351L39.9943 70.793L38.7157 70.9857L37.7073 64.3511L38.986 64.1584L39.0977 64.8936C39.2166 64.625 39.4057 64.3948 39.6651 64.2028C39.9246 64.0109 40.2404 63.8869 40.6126 63.8308C41.0328 63.7675 41.4333 63.8172 41.8142 63.9799C42.1951 64.1426 42.5171 64.4029 42.7803 64.7606C43.0383 65.1253 43.2059 65.5616 43.2832 66.0697ZM39.3544 66.6433C39.4008 66.9481 39.5003 67.1991 39.6531 67.3962C39.8067 67.5992 39.9943 67.7422 40.2156 67.825C40.4379 67.9138 40.6661 67.9405 40.9002 67.9052C41.1343 67.87 41.3418 67.7806 41.5227 67.6372C41.7035 67.4938 41.8401 67.302 41.9326 67.0619C42.02 66.8286 42.0409 66.5625 41.9955 66.2637C41.9501 65.9648 41.8501 65.7109 41.6955 65.5018C41.5358 65.2997 41.3479 65.1537 41.1316 65.0641C40.9093 64.9753 40.6841 64.9481 40.456 64.9824C40.2219 65.0177 40.0114 65.1075 39.8245 65.2518C39.6386 65.4021 39.502 65.5939 39.4146 65.8272C39.3282 66.0664 39.3081 66.3384 39.3544 66.6433Z" fill="white"/>
                                        <path d="M34.598 64.1825C35.0833 64.203 35.5156 64.3272 35.8948 64.555C36.2737 64.7888 36.567 65.1068 36.7747 65.5089C36.9763 65.9107 37.0662 66.3683 37.0443 66.8817C37.0224 67.395 36.8909 67.8433 36.6497 68.2264C36.4024 68.6092 36.0771 68.898 35.6738 69.0927C35.2703 69.2934 34.8258 69.3835 34.3406 69.363C33.8553 69.3425 33.4202 69.2152 33.0351 68.9811C32.6499 68.753 32.3534 68.4379 32.1457 68.0359C31.9319 67.6335 31.836 67.1757 31.8579 66.6623C31.8798 66.1489 32.0174 65.701 32.2707 65.3184C32.518 64.9355 32.8464 64.6439 33.2561 64.4434C33.6594 64.2487 34.1067 64.1617 34.598 64.1825ZM34.5509 65.2877C34.3204 65.278 34.1027 65.3232 33.8979 65.4235C33.6868 65.5295 33.5158 65.6917 33.3849 65.9101C33.2541 66.1284 33.1818 66.3976 33.1682 66.7177C33.1478 67.1949 33.2596 67.5657 33.5035 67.8301C33.7412 68.1003 34.042 68.2431 34.4059 68.2585C34.7699 68.2739 35.0818 68.157 35.3415 67.9078C35.595 67.6644 35.7319 67.3041 35.7523 66.827C35.7726 66.3499 35.67 65.9764 35.4446 65.7067C35.2127 65.4428 34.9148 65.3031 34.5509 65.2877Z" fill="white"/>
                                        <path d="M23.4409 67.2095L26.1675 62.7347L27.4961 63.0878L27.4759 66.7146L29.2735 63.5602L30.611 63.9157L30.7441 69.1505L29.4946 68.8184L29.6235 64.8921L27.6996 68.3413L26.3973 67.9952L26.471 64.0636L24.6199 67.5229L23.4409 67.2095Z" fill="white"/>
                                        <path d="M19.5977 65.3002C19.0651 65.0229 18.7233 64.63 18.5724 64.1216C18.4187 63.6186 18.4995 63.0671 18.8146 62.4671L20.1865 59.8557L21.3162 60.4441L20.0246 62.9028C19.8389 63.2564 19.7856 63.5731 19.8646 63.8531C19.9408 64.1384 20.1323 64.3609 20.4389 64.5206C20.751 64.6831 21.0491 64.7156 21.3333 64.618C21.6093 64.5229 21.8402 64.2987 22.0259 63.9451L23.3176 61.4864L24.4474 62.0748L22.1089 66.5262L20.9791 65.9378L21.2704 65.3834C21.0184 65.4978 20.7487 65.5483 20.4611 65.535C20.1652 65.5242 19.8775 65.4459 19.5977 65.3002Z" fill="white"/>
                                        <path d="M12.3004 58.2444L13.0236 57.4529L15.8799 60.0401L15.1567 60.8316L12.3004 58.2444Z" fill="white"/>
                                        <path d="M11.5889 51.1984C11.8337 51.5664 11.9917 51.9407 12.0629 52.3215C12.1309 52.6973 12.1028 53.0493 11.9789 53.3776C11.855 53.7058 11.6328 53.9793 11.3121 54.198L10.5578 53.064C10.7654 52.9053 10.8872 52.7162 10.9232 52.4967C10.9559 52.2721 10.8918 52.0389 10.7309 51.7969C10.5666 51.55 10.3793 51.3943 10.169 51.33C9.95365 51.269 9.7498 51.3019 9.55745 51.4288C9.40053 51.5322 9.30331 51.6652 9.26577 51.8278C9.22823 51.9903 9.23024 52.163 9.27178 52.3459C9.30491 52.527 9.36986 52.767 9.46663 53.0657C9.59337 53.4752 9.68073 53.82 9.72872 54.1002C9.76829 54.3786 9.74134 54.661 9.64786 54.9475C9.54596 55.2322 9.32798 55.4847 8.99389 55.705C8.68006 55.912 8.35476 56.0141 8.01798 56.0114C7.68121 56.0088 7.3582 55.9064 7.04897 55.7042C6.73468 55.5054 6.45684 55.2246 6.21545 54.8617C5.85337 54.3174 5.69416 53.788 5.73781 53.2734C5.77305 52.7571 6.01684 52.3244 6.46918 51.9753L7.24363 53.1396C7.06811 53.2698 6.97076 53.4464 6.95156 53.6693C6.92395 53.8906 6.98893 54.1196 7.1465 54.3565C7.28396 54.5631 7.44774 54.6944 7.63786 54.7503C7.82462 54.8012 8.01924 54.7598 8.22171 54.6263C8.36344 54.5328 8.45222 54.409 8.48805 54.2549C8.51546 54.099 8.51428 53.9331 8.4845 53.7569C8.44631 53.5791 8.38136 53.3391 8.28966 53.0371C8.16292 52.6276 8.07808 52.2811 8.03516 51.9976C7.99223 51.7141 8.02004 51.4275 8.11858 51.1378C8.21712 50.848 8.4309 50.5946 8.75992 50.3776C9.04339 50.1907 9.35521 50.0902 9.69541 50.0761C10.0356 50.0621 10.373 50.1477 10.7075 50.3332C11.037 50.5219 11.3308 50.8104 11.5889 51.1984Z" fill="white"/>
                                        <path d="M7.73416 49.9285C7.2548 50.1346 6.79463 50.2172 6.35365 50.1764C5.9071 50.1379 5.51167 49.9885 5.16736 49.7283C4.81748 49.4705 4.54626 49.1196 4.35372 48.6756C4.10581 48.104 4.04616 47.5699 4.17475 47.0734C4.29536 46.5738 4.58718 46.1488 5.0502 45.7983L5.59534 47.0553C5.42356 47.2082 5.31683 47.3891 5.27515 47.5979C5.22549 47.8037 5.2512 48.0231 5.35228 48.2562C5.49669 48.5892 5.73364 48.8001 6.06314 48.8889C6.38706 48.9801 6.77198 48.9298 7.2179 48.7381C7.65824 48.5488 7.95892 48.3043 8.11994 48.0046C8.27538 47.7073 8.2809 47.3921 8.13649 47.0591C7.93191 46.5874 7.58652 46.3704 7.10032 46.4083L6.55518 45.1512C7.11219 45.0632 7.62025 45.1444 8.07933 45.3948C8.53842 45.6451 8.89071 46.0534 9.1362 46.6195C9.32875 47.0635 9.40208 47.4994 9.35622 47.9274C9.30478 48.3578 9.14281 48.7468 8.87031 49.0944C8.59224 49.4444 8.21352 49.7225 7.73416 49.9285Z" fill="white"/>
                                        <path d="M3.53371 43.6156C3.23367 43.5253 2.97706 43.3737 2.76389 43.1607C2.54918 42.9419 2.39861 42.6688 2.31217 42.3414L3.60693 42.0025L3.6926 42.327C3.79447 42.7129 3.96266 42.9814 4.19717 43.1325C4.43014 43.2778 4.77269 43.2913 5.22483 43.173L7.65581 42.5367L7.97995 43.7645L3.10037 45.0416L2.77623 43.8138L3.53371 43.6156Z" fill="white"/>
                                        <path d="M6.96918 38.2393C7.01779 38.7205 6.95582 39.1641 6.78327 39.5703C6.60469 39.977 6.33031 40.3113 5.96013 40.5732C5.58934 40.829 5.14721 40.9827 4.63373 41.0341C4.12025 41.0855 3.65594 41.0196 3.24079 40.8364C2.82504 40.6472 2.4917 40.3676 2.24077 39.9979C1.98381 39.6287 1.83102 39.2035 1.78241 38.7223C1.7338 38.2411 1.79848 37.7941 1.97646 37.3814C2.1484 36.9693 2.41946 36.6323 2.78963 36.3704C3.15921 36.1025 3.60073 35.9428 4.11421 35.8914C4.62769 35.84 5.09261 35.9119 5.50897 36.1072C5.92472 36.2964 6.26138 36.5786 6.51896 36.9538C6.76989 37.3236 6.91996 37.7521 6.96918 38.2393ZM5.86369 38.35C5.8406 38.1215 5.76466 37.9134 5.63587 37.7258C5.50043 37.5328 5.31488 37.3873 5.07921 37.2894C4.84355 37.1915 4.56563 37.1586 4.24546 37.1906C3.76822 37.2384 3.41551 37.4013 3.18731 37.6794C2.95246 37.952 2.85326 38.2687 2.88972 38.6296C2.92618 38.9905 3.08674 39.2813 3.37142 39.5019C3.64944 39.717 4.02707 39.8007 4.5043 39.7529C4.98154 39.7051 5.33819 39.5509 5.57425 39.2904C5.80366 39.0243 5.90014 38.7109 5.86369 38.35Z" fill="white"/>
                                        <path d="M0.0491941 33.242L6.78345 33.5009L6.73426 34.7694L0 34.5105L0.0491941 33.242Z" fill="white"/>
                                        <path d="M0.348434 30.2232L7.03653 31.0486L6.8797 32.3084L0.191604 31.483L0.348434 30.2232Z" fill="white"/>
                                        <path d="M5.12205 27.1952C4.64192 27.024 4.24987 26.7782 3.94591 26.4579C3.64398 26.1319 3.45237 25.7682 3.37106 25.3667C3.28975 24.9653 3.32177 24.5626 3.46713 24.1585C3.57768 23.8512 3.75171 23.5825 3.9892 23.3526C4.22099 23.1206 4.47802 22.9618 4.76031 22.8763L2.50541 22.0721L2.94149 20.8599L9.28606 23.1226L8.84999 24.3348L8.14694 24.0841C8.32806 24.3156 8.44196 24.5906 8.48863 24.9091C8.5353 25.2275 8.49517 25.5632 8.36824 25.916C8.22493 26.3144 7.99102 26.6419 7.66651 26.8986C7.34199 27.1553 6.95917 27.3142 6.51803 27.3752C6.07322 27.4285 5.6079 27.3685 5.12205 27.1952ZM6.47813 23.4793C6.18662 23.3753 5.91751 23.3436 5.6708 23.384C5.41836 23.4224 5.20304 23.519 5.02483 23.6738C4.84091 23.8265 4.70903 24.0138 4.62918 24.2358C4.54934 24.4577 4.52993 24.682 4.57097 24.9085C4.61202 25.1351 4.71678 25.3458 4.88527 25.5407C5.0501 25.7279 5.27541 25.8725 5.5612 25.9744C5.84699 26.0764 6.11896 26.1091 6.37711 26.0728C6.63158 26.0287 6.84976 25.9331 7.03164 25.7861C7.21557 25.6334 7.34643 25.4489 7.42422 25.2326C7.50407 25.0106 7.52449 24.7835 7.4855 24.5513C7.44079 24.3171 7.33603 24.1063 7.1712 23.9191C7.00066 23.7299 6.76964 23.5833 6.47813 23.4793Z" fill="white"/>
                                        <path d="M10.8077 20.6334C10.5607 21.0497 10.2469 21.3707 9.86626 21.5962C9.48044 21.8186 9.06051 21.9262 8.60647 21.9189C8.15551 21.9064 7.70787 21.7695 7.26354 21.5081C6.81921 21.2468 6.48428 20.9199 6.25875 20.5275C6.03632 20.1298 5.93398 19.708 5.95175 19.2621C5.96428 18.8131 6.09407 18.3804 6.34112 17.964C6.58816 17.5476 6.90614 17.2256 7.29505 16.998C7.67874 16.7673 8.0976 16.6556 8.55164 16.6629C9.00877 16.6649 9.4595 16.7967 9.90383 17.058C10.3482 17.3194 10.68 17.6515 10.8994 18.0543C11.1218 18.4519 11.2252 18.8779 11.2096 19.3321C11.1918 19.778 11.0579 20.2118 10.8077 20.6334ZM9.8511 20.0707C9.96845 19.8729 10.031 19.6605 10.0388 19.4333C10.0445 19.198 9.98153 18.9713 9.84987 18.7535C9.71822 18.5356 9.51386 18.3452 9.23681 18.1822C8.82384 17.9393 8.44273 17.8626 8.09348 17.9521C7.74209 18.0333 7.47376 18.23 7.28847 18.5423C7.10319 18.8546 7.05963 19.1835 7.1578 19.5292C7.25383 19.8665 7.50833 20.1566 7.9213 20.3995C8.33426 20.6424 8.71335 20.7285 9.05857 20.6577C9.40164 20.5786 9.66582 20.383 9.8511 20.0707Z" fill="white"/>
                                        <path d="M13.3447 9.41548L16.0349 13.9121L15.0985 14.9148L11.8911 13.1959L13.8458 16.2562L12.9032 17.2656L8.19748 14.9272L9.07809 13.9842L12.5003 15.9392L10.3432 12.6295L11.261 11.6467L14.7138 13.5557L12.5137 10.3053L13.3447 9.41548Z" fill="white"/>
                                        <path d="M16.897 6.86963C17.3933 6.53188 17.9022 6.41615 18.4238 6.52245C18.9419 6.62375 19.3929 6.95388 19.7767 7.51281L21.447 9.9457L20.3945 10.6621L18.8218 8.37148C18.5956 8.0421 18.3399 7.84637 18.0546 7.78429C17.7658 7.71721 17.4786 7.7809 17.1929 7.97537C16.9022 8.17324 16.7333 8.42 16.6862 8.71565C16.6408 9.0029 16.7311 9.31122 16.9572 9.64059L18.53 11.9312L17.4774 12.6477L14.6301 8.50055L15.6826 7.78412L16.0372 8.30064C16.0542 8.02545 16.1362 7.76461 16.2832 7.5181C16.4318 7.26319 16.6364 7.04703 16.897 6.86963Z" fill="white"/>
                                        <path d="M26.5458 3.76533L26.9081 4.77266L23.2729 6.06912L22.9105 5.06178L26.5458 3.76533Z" fill="white"/>
                                        <path d="M33.0017 6.46104C32.5606 6.50352 32.1544 6.4667 31.7833 6.3506C31.4181 6.23392 31.1191 6.04407 30.8862 5.78105C30.6532 5.51803 30.515 5.19426 30.4716 4.80975L31.8313 4.67883C31.8746 4.93582 31.9851 5.13168 32.1629 5.2664C32.3467 5.40053 32.5837 5.45364 32.8737 5.42571C33.1698 5.3972 33.3958 5.3056 33.5516 5.15091C33.7067 4.99021 33.7732 4.79553 33.751 4.56688C33.7329 4.38035 33.6606 4.23244 33.5341 4.12316C33.4076 4.01387 33.2535 3.93457 33.0718 3.88525C32.8956 3.82934 32.6523 3.77381 32.342 3.71866C31.9193 3.63788 31.5725 3.55284 31.3016 3.46354C31.0361 3.36763 30.7983 3.21136 30.5881 2.99473C30.3835 2.7715 30.2618 2.46132 30.2233 2.06419C30.187 1.69113 30.2491 1.35719 30.4096 1.06236C30.5701 0.76754 30.8125 0.531631 31.1369 0.354637C31.4607 0.171625 31.8402 0.0591734 32.2753 0.0172806C32.9279 -0.0455587 33.4722 0.0629835 33.9081 0.342907C34.3494 0.616232 34.6187 1.03366 34.7159 1.5952L33.3199 1.72961C33.2868 1.51415 33.1759 1.34567 32.9872 1.22415C32.804 1.09603 32.5703 1.04564 32.2863 1.07299C32.0385 1.09684 31.8452 1.17922 31.7064 1.32013C31.5737 1.46045 31.519 1.65096 31.5423 1.89164C31.5587 2.06012 31.6268 2.19629 31.7467 2.30014C31.8721 2.39739 32.0198 2.47427 32.19 2.53076C32.3656 2.58066 32.6089 2.63619 32.9198 2.69735C33.3425 2.77813 33.6896 2.86618 33.9611 2.9615C34.2326 3.05683 34.4738 3.21581 34.6845 3.43846C34.8952 3.66111 35.0196 3.96799 35.0575 4.3591C35.0903 4.69606 35.033 5.01739 34.8858 5.32308C34.7386 5.62878 34.5043 5.88516 34.1828 6.09224C33.8607 6.29331 33.4671 6.41624 33.0017 6.46104Z" fill="white"/>
                                        <path d="M36.1956 3.65722C36.2399 3.13921 36.3843 2.69641 36.629 2.32884C36.8742 1.95525 37.1933 1.67893 37.5864 1.49987C37.98 1.31479 38.4188 1.24274 38.9027 1.28373C39.5258 1.3365 40.0267 1.53665 40.4052 1.8842C40.7904 2.22623 41.0286 2.68323 41.1199 3.25519L39.7496 3.13915C39.6956 2.91616 39.586 2.737 39.4208 2.60167C39.2622 2.46084 39.0559 2.37966 38.8018 2.35814C38.4388 2.3274 38.1402 2.43558 37.9058 2.68268C37.6719 2.92376 37.5344 3.28524 37.4933 3.76711C37.4526 4.24296 37.5268 4.62237 37.7159 4.90533C37.9055 5.18227 38.1818 5.33611 38.5448 5.36685C39.059 5.4104 39.4143 5.20994 39.6107 4.76548L40.981 4.88152C40.7955 5.41184 40.4835 5.81921 40.0448 6.10361C39.6062 6.38802 39.0783 6.50409 38.4613 6.45184C37.9773 6.41085 37.5568 6.26907 37.1997 6.02648C36.8432 5.77788 36.5755 5.45186 36.3967 5.04843C36.2184 4.63897 36.1514 4.17524 36.1956 3.65722Z" fill="white"/>
                                        <path d="M43.6777 2.92544C43.8991 2.7046 44.1545 2.55085 44.4436 2.46418C44.7387 2.37891 45.0516 2.37557 45.3823 2.45414L45.0716 3.75071L44.7438 3.67283C44.3541 3.58023 44.0369 3.60121 43.7925 3.73579C43.5539 3.87177 43.3803 4.16614 43.2718 4.61891L42.6884 7.05327L41.4482 6.75861L42.6193 1.87225L43.8595 2.16691L43.6777 2.92544Z" fill="white"/>
                                        <path d="M46.9333 8.50518C46.4846 8.32012 46.1214 8.05583 45.8437 7.7123C45.5683 7.36319 45.4024 6.96432 45.3459 6.5157C45.2949 6.0694 45.3682 5.60888 45.5657 5.13415C45.7632 4.65941 46.0412 4.28333 46.3997 4.00589C46.7639 3.73076 47.169 3.57064 47.6149 3.52552C48.0631 3.47482 48.5116 3.542 48.9604 3.72706C49.4091 3.91212 49.774 4.18036 50.0549 4.53178C50.3382 4.87762 50.5081 5.27485 50.5647 5.72347C50.6268 6.17441 50.5591 6.63724 50.3617 7.11197C50.1642 7.5867 49.8806 7.96048 49.5108 8.23329C49.1466 8.50842 48.7376 8.67018 48.2837 8.71856C47.8378 8.76368 47.3877 8.69255 46.9333 8.50518ZM47.3585 7.48311C47.5716 7.57101 47.7917 7.60287 48.0186 7.57867C48.2535 7.55121 48.47 7.45728 48.6681 7.2969C48.8663 7.13652 49.0269 6.90832 49.15 6.61231C49.3336 6.17109 49.3563 5.78461 49.2183 5.45288C49.0883 5.11788 48.8549 4.88098 48.5184 4.74219C48.1818 4.60339 47.8486 4.60665 47.5187 4.75197C47.1968 4.89401 46.944 5.18564 46.7605 5.62687C46.5769 6.06809 46.5446 6.45389 46.6635 6.78426C46.7903 7.11137 47.0219 7.34431 47.3585 7.48311Z" fill="white"/>
                                        <path d="M54.3448 4.63101L50.9599 10.4334L49.8574 9.79573L53.2423 3.99338L54.3448 4.63101Z" fill="white"/>
                                        <path d="M56.987 6.37486L53.1027 11.8583L52.0608 11.1266L55.9451 5.64317L56.987 6.37486Z" fill="white"/>
                                        <path d="M57.4811 12.0372C57.8584 11.6955 58.2604 11.4662 58.6871 11.3493C59.1179 11.2369 59.5304 11.2392 59.9246 11.3562C60.3187 11.4732 60.6609 11.6904 60.9509 12.0079C61.1716 12.2494 61.3281 12.5285 61.4205 12.8452C61.5174 13.1579 61.5375 13.4584 61.4808 13.7468L63.2526 12.1419L64.1228 13.0944L59.1377 17.6099L58.2675 16.6574L58.8199 16.157C58.5295 16.2075 58.2322 16.1786 57.9279 16.0701C57.6236 15.9616 57.3448 15.7688 57.0915 15.4915C56.8055 15.1785 56.6248 14.8191 56.5495 14.4133C56.4743 14.0075 56.5131 13.5964 56.6661 13.1799C56.8277 12.7639 57.0994 12.3829 57.4811 12.0372ZM60.1396 14.9738C60.3687 14.7664 60.5232 14.5447 60.6032 14.3088C60.6876 14.0689 60.7031 13.8342 60.6495 13.6049C60.6004 13.3715 60.4962 13.1676 60.3368 12.9932C60.1775 12.8188 59.9878 12.6965 59.7676 12.6262C59.5474 12.5559 59.3114 12.5491 59.0594 12.6057C58.816 12.6627 58.582 12.7929 58.3575 12.9963C58.1329 13.1997 57.9762 13.4234 57.8872 13.6675C57.8069 13.9119 57.7892 14.1486 57.8342 14.3775C57.8833 14.6109 57.9854 14.8125 58.1407 14.9825C58.3 15.1569 58.4918 15.2814 58.7161 15.3562C58.9448 15.4269 59.1809 15.4337 59.4242 15.3767C59.6721 15.3156 59.9106 15.1813 60.1396 14.9738Z" fill="white"/>
                                        <path d="M60.5938 20.0434C60.3391 19.6316 60.2004 19.2055 60.1776 18.7651C60.1599 18.3216 60.2602 17.9015 60.4785 17.505C60.6999 17.1137 61.0303 16.7833 61.4698 16.5138C61.9092 16.2444 62.3558 16.1019 62.8096 16.0863C63.2667 16.0759 63.6891 16.1825 64.077 16.4061C64.47 16.6266 64.7939 16.9427 65.0486 17.3545C65.3033 17.7663 65.441 18.1965 65.4619 18.6452C65.4879 19.0908 65.3917 19.5118 65.1735 19.9083C64.9585 20.3099 64.6312 20.6455 64.1918 20.9149C63.7523 21.1843 63.3025 21.3217 62.8423 21.327C62.3853 21.3374 61.9587 21.2299 61.5625 21.0042C61.1746 20.7806 60.8517 20.4603 60.5938 20.0434ZM61.5399 19.4633C61.6609 19.6589 61.8204 19.8131 62.0185 19.9259C62.225 20.0407 62.4557 20.0909 62.7107 20.0766C62.9656 20.0622 63.2301 19.9711 63.5041 19.8031C63.9125 19.5526 64.1584 19.2528 64.2419 18.9034C64.3336 18.5561 64.284 18.228 64.093 17.9191C63.902 17.6103 63.63 17.4185 63.2772 17.3438C62.9327 17.271 62.5563 17.3598 62.1479 17.6102C61.7395 17.8607 61.4862 18.1544 61.3881 18.4915C61.2983 18.8305 61.3489 19.1544 61.5399 19.4633Z" fill="white"/>
                                        <path d="M69.4109 27.6402L64.1604 27.8922L63.7113 26.598L66.7422 24.5864L63.1106 24.8668L62.6585 23.564L66.9425 20.5265L67.3648 21.7436L64.0238 23.8331L67.9715 23.492L68.4117 24.7604L65.0971 26.8984L69.0124 26.4917L69.4109 27.6402Z" fill="white"/>
                                        <path d="M69.9843 31.9695C70.049 32.5645 69.9112 33.0658 69.5708 33.4735C69.2365 33.8804 68.7313 34.1204 68.0553 34.1933L65.1127 34.5108L64.9753 33.2487L67.7459 32.9498C68.1443 32.9068 68.4383 32.7748 68.628 32.5536C68.8237 32.3319 68.9029 32.0497 68.8656 31.7071C68.8277 31.3585 68.6889 31.0938 68.4494 30.9129C68.2166 30.7374 67.9009 30.6711 67.5026 30.7141L64.732 31.013L64.5946 29.7509L69.6106 29.2097L69.748 30.4718L69.1232 30.5392C69.3589 30.6841 69.5511 30.8792 69.6999 31.1246C69.8555 31.3753 69.9503 31.657 69.9843 31.9695Z" fill="white"/>
                                    </svg>

                                    <svg className='scroll__arrow'  viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.8598 7.21894L12.4076 5.76677L7.99871 10.1756V0.330902H5.94472V10.1756L1.53585 5.76677L0.0836792 7.21894L6.97172 14.107L13.8598 7.21894Z" fill="white"/>
                                    </svg>

                                </div>
                                <div className='header__item header__btn' ref={ref}>
                                    <a href='#expertise' data-scroll-to>
                                        <Button text='Discover What I Do'></Button>
                                    </a>
                                </div>
                                <span className='header__item header__year' ref={ref}>2024</span>
                            </div>

                            <div className='header__imageContainer grid' ref={ref}>
                                <div className='header__imageItems' ref={ref}>
                                    <div className='image__cover'>
                                        <div className='header__image' data-scroll data-scroll-delay="0.25" data-scroll-speed="-0.5" style={{ backgroundImage: `url(${hero1})` }}></div>
                                    </div>
                                </div>
                                <div className='header__imageItems hero-tengah' ref={ref}>
                                    <div className='image__cover'>
                                        <div className='header__image' data-scroll data-scroll-delay="0.25" data-scroll-speed="-0.5" style={{ backgroundImage: `url(${hero2})` }}></div>
                                    </div>
                                    
                                </div>
                                <div className='header__imageItems' ref={ref}>
                                    <div className='image__cover'>
                                        <div className='header__image' data-scroll data-scroll-delay="0.25" data-scroll-speed="-0.5" style={{ backgroundImage: `url(${hero3})` }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <About></About>

                    <Expertise/>

                    <Mockup/>

                    <WorkHomePage/>

                    <MySkill/>

                    <CallToAction/>
                    

                    <div className="mycircleicon"></div>

                    <div className="cursor-follower" >
                        <div className='blobs1'></div>
                        <div className='blobs2'></div>
                        <div className='blobs3'></div>
                    </div>

                    <Footer/>
                </main>

                
            </div>

            
        </>


    );

    
}

export default transition(Home);