import React, { useEffect, useRef} from 'react';
import {gsap, ScrollTrigger} from 'gsap/src/all';
import transition from '../Animations/transition';


import { Link } from 'react-router-dom';
import SplitType from 'split-type';
import useLocoScroll from '../Animations/useLocoScroll';

import CallToAction from '../Sections/CallToAction/CallToAction';
import Footer from '../Sections/Footer/Footer';

import img1 from '../../Assets/Image/henbuk_thumbnail.webp';
import img2 from '../../Assets/Image/sass_thumbnail.webp';
import img3 from '../../Assets/Image/spotlight_thumbnail.webp';
import img4 from '../../Assets/Image/noabike_thumbnail.webp';
import img5 from '../../Assets/Image/digivition_thumbnail.webp';
import img6 from '../../Assets/Image/exotic_komodo_thumbnail.webp';
import { Helmet } from 'react-helmet';

const Work = ({isLoading}) => {

    const containerRef = useRef(null)
    const ref = useRef(null)

    
    // const startLocoScroll = true;

    useLocoScroll(!isLoading);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        const myTitle = new SplitType('.aboutHeader__titleContainer h1');
        const myDesc = new SplitType('.aboutHeader__desc p', { types: 'words, chars' });
        
        if(isLoading === false){

            const timeline_home = gsap.timeline({
                // paused: true
            });
            timeline_home.from('.aboutHeader__titleContainer .line .word', {
                duration: 0.8,
                skewY: 15,
                y: 200,
                stagger : 0.08,
                ease: "power2.out",
                delay: 5.5,
            })
            .from('.pree__titleContainer',{
                x: -100,
                // delay: .2,
                opacity: 0,
                duration: 0.5,
                ease: "power2.out",
            }, '-=1')
            .from('.aboutHeader__desc .word .char',{
                duration: 0.5,
                skewY: 15,
                y: 100,
                opacity : 0,
                stagger : 0.003,
                ease: "power2.out",
            }, '-=1')
        }
        else{
            
            const timeline_home = gsap.timeline({
                // paused: true
            });
            timeline_home.from('.aboutHeader__titleContainer .line .word', {
                duration: 0.8,
                skewY: 15,
                y: 200,
                stagger : 0.08,
                ease: "power2.out",
            })
            .from('.pree__titleContainer',{
                x: -100,
                // delay: .2,
                opacity: 0,
                duration: 0.5,
                ease: "power2.out",
            }, '-=1')
            .from('.aboutHeader__desc .word .char',{
                duration: 0.5,
                skewY: 15,
                y: 100,
                opacity : 0,
                stagger : 0.003,
                ease: "power2.out",
            }, '-=1')
        }
        
        

        gsap.set(".cursor-follower", {xPercent: 50, yPercent: 50});

        const ball = document.querySelector(".cursor-follower");
        const pos = { x: window.innerWidth / 2.5, y: 150 };
        const mouse = { x: pos.x, y: pos.y };
        const speed = 0.03;

        const xSet = gsap.quickSetter(ball, "x", "px");
        const ySet = gsap.quickSetter(ball, "y", "px");

        window.addEventListener("mousemove", e => {    
        mouse.x = e.x;
        mouse.y = e.y;  
        });

        gsap.ticker.add(() => {
        
            // adjust speed for higher refresh monitors
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
            
            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });
    },[])


    useEffect(() =>{

        let items = gsap.utils.toArray(".work__item"),
        cursor = document.querySelector(".mycircleicon"),
        xTo = gsap.quickTo(cursor, "x", {duration: 0.3, ease: "power3"}),
        yTo = gsap.quickTo(cursor, "y", {duration: 0.3, ease: "power3"});

        gsap.set(cursor, {autoAlpha: 1, scale: 0});

        window.addEventListener("mousemove", (e) => {
            xTo(e.pageX);
            yTo(e.pageY);
        });

        items.forEach((item) => {
            item.addEventListener("mouseenter", () => {
                gsap.to(cursor, {scale: 1, duration: 0.2, })
            });
            item.addEventListener("mouseleave", () => {
                gsap.to(cursor, {scale: 0, duration: 0.2,});
            });
        });


    }, [])



    return (
        <>
            <Helmet>
                <title>Aditya Semara | Works</title>
                <meta name="description" content="Aditya Semara - Creative Website Developer and Brand Identity Designer"/>
                <meta name="keywords" content="Aditya Semara, Website Developer, Brand Identity Designer, Creative Design"/>
                <meta name="author" content="Aditya Semara"/>
            </Helmet>

            <div data-load-container>
                <main id='main-container' data-scroll-container ref={containerRef}>
                <section className='workHeader aboutHeader' id='workSection' data-scroll-section>
                        <div className='aboutHeader__container container'>
                            <div className='aboutHeader__titleContainer'>
                                <div className='pree__titleContainer'>
                                    <div className='pree-line'></div>
                                    <pre className="pree-title">Works</pre>
                                </div>
                                <h1 className='title'>
                                    I do amazing things <br/> with amazing People.
                                </h1>
                            </div>
                            <div className='aboutHeader__desc'>
                                <p>
                                    As a website developer and brand identity designer, I specialize in crafting digital experiences that resonate. Collaborating with amazing individuals, I bring creativity to code, shaping not just websites but compelling brand identities that leave a lasting impact. Together, we turn ideas into extraordinary online and visual narratives.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className='work work__page' id='work' data-scroll-section>
                        <div className='work__container container'>
                            
                            <div className='work__data grid'>
                                <Link to='/works/henbuk' className='work__item'>
                                    <div className='work__number'>
                                        <span>01</span>
                                    </div>
                                    <div className='work__imageContainer'>
                                        <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                            <picture>
                                                <source srcSet={img1} type="image/webp" />
                                                <img src={img1} alt="Aditya Semara | Henbuk Mockup" loading="lazy" />
                                            </picture>
                                        </div>
                                    </div>
                                    <div className='work__tag'>
                                        <span>Brand Identity</span>
                                    </div>
                                    <div className='work__detail'>
                                        <h3 className='work__title'>Henbuk</h3>
                                        <div className='work__line'></div>
                                    </div>
                                </Link>

                                <Link to='/works/sass-bali-development' className='work__item'>
                                    <div className='work__number'>
                                        <span>02</span>
                                    </div>
                                    <div className='work__imageContainer'>
                                        <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                            <picture>
                                                <source srcSet={img2} type="image/webp" />
                                                <img src={img2} alt="Aditya Semara | Sass Bali Development Mockup" loading="lazy" />
                                            </picture>
                                        </div>
                                    </div>
                                    <div className='work__tag'>
                                        <span>Brand Identity</span>
                                    </div>
                                    <div className='work__detail'>
                                        <h3 className='work__title'>Sass Bali Development</h3>
                                        <div className='work__line'></div>
                                    </div>
                                </Link>

                                <Link to='/works/spotlight' className='work__item'>
                                    <div className='work__number'>
                                        <span>03</span>
                                    </div>
                                    <div className='work__imageContainer'>
                                        <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                            <picture>
                                                <source srcSet={img3} type="image/webp" />
                                                <img src={img3} alt="Aditya Semara | Spotlight Mockup" loading="lazy" />
                                            </picture>
                                        </div>
                                    </div>
                                    <div className='work__tag'>
                                        <span>Web Development</span>
                                    </div>
                                    <div className='work__detail'>
                                        <h3 className='work__title'>Spotlight</h3>
                                        <div className='work__line'></div>
                                    </div>
                                </Link>

                                <Link to='/works/noa-bike' className='work__item'>
                                    <div className='work__number'>
                                        <span>04</span>
                                    </div>
                                    <div className='work__imageContainer'>
                                        <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                            <picture>
                                                <source srcSet={img4} type="image/webp" />
                                                <img src={img4} alt="Aditya Semara | Noa Bike Mockup" loading="lazy" />
                                            </picture>
                                        </div>
                                    </div>
                                    <div className='work__tag'>
                                        <span>UI/UX Design, Branding</span>
                                    </div>
                                    <div className='work__detail'>
                                        <h3 className='work__title'>Noa Bike</h3>
                                        <div className='work__line'></div>
                                    </div>
                                </Link>

                                <Link to='/works/digivition' className='work__item'>
                                    <div className='work__number'>
                                        <span>05</span>
                                    </div>
                                    <div className='work__imageContainer'>
                                        <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                            <picture>
                                                <source srcSet={img5} type="image/webp" />
                                                <img src={img5} alt="Aditya Semara | Digivition Mockup" loading="lazy" />
                                            </picture>
                                        </div>
                                    </div>
                                    <div className='work__tag'>
                                        <span>Website Development</span>
                                    </div>
                                    <div className='work__detail'>
                                        <h3 className='work__title'>Digivition</h3>
                                        <div className='work__line'></div>
                                    </div>
                                </Link>

                                <Link to='/works/exotic-komodo' className='work__item'>
                                    <div className='work__number'>
                                        <span>06</span>
                                    </div>
                                    <div className='work__imageContainer'>
                                        <div className='work__imageCover' data-scroll data-scroll-delay="0.25" data-scroll-speed="-1">
                                            <picture>
                                                <source srcSet={img6} type="image/webp" />
                                                <img src={img6} alt="Aditya Semara | Exotic Komodo Mockup" loading="lazy" />
                                            </picture>
                                        </div>
                                    </div>
                                    <div className='work__tag'>
                                        <span>UI/UX Design</span>
                                    </div>
                                    <div className='work__detail'>
                                        <h3 className='work__title'>Exotic Komodo</h3>
                                        <div className='work__line'></div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </section>

                    <CallToAction/>

                    <div className="mycircleicon"></div>

                    <div className="cursor-follower" >
                        <div className='blobs1'></div>
                        <div className='blobs2'></div>
                        <div className='blobs3'></div>
                    </div>

                    <Footer/>
                </main>
            </div>

            
            
        </>
    );
};

export default transition(Work);