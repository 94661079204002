import './Assets/Styles/Style.scss';
import { Routes, Route, useLocation, useOutletContext } from 'react-router-dom';
import React, { useEffect, useRef, useState} from 'react';
import { AnimatePresence } from 'framer-motion';
import {gsap, CSSPlugin, Expo} from 'gsap/src/all';

import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Pages/Home';
import Contact from './Components/Pages/Contact';
import About from './Components/Pages/About';
import PageNotFound from './Components/Pages/PageNotFound';
import Work from './Components/Pages/Work';
import Henbuk from './Components/Pages/WorkDetail/Henbuk';
import SassBaliDevelopment from './Components/Pages/WorkDetail/SassBaliDevelopment';
import NoaBike from './Components/Pages/WorkDetail/NoaBike';
import Spotlight from './Components/Pages/WorkDetail/Spotlight';
import Digivition from './Components/Pages/WorkDetail/Digivition';
import ExoticKomodo from './Components/Pages/WorkDetail/ExoticKomodo';

const App = () => {

  const location = useLocation();


  const [isLoading, setLoading] = useState(false)
  const [counter, setCounter] = useState(0);



  useEffect(() => {
    const count = setInterval(() => {
      setCounter((counter) =>
        counter < 100
          ? counter + 1
          : (clearInterval(count), setCounter(100), reveal())
      );
    }, 25);

  }, []);

  const reveal = () => {
    const t1 = gsap.timeline({
      onComplete: () => {
        setLoading(true)
      },
    });
    t1.to(".progress__barContainer", {
      width: "100%",
      ease: Expo.easeInOut,
      duration: 0.5,
      delay: 0.7,
    })
    .to(".counter", { opacity: 0, duration: 0.3 })
    .to(".counter", { display: "none", duration: 0.3 })
    .to(".progress__barContainer", {
      height: "100%",
      ease: Expo.easeInOut,
      duration: 0.7,
      delay: 0.5,
    })
    .to(".loader__container", { opacity: 0, duration: 0.3, zIndex: -1 })
  }

  return (
    <>
      
      <div className='loader__container'>
        <div className='progress__barContainer'>
          <div className='progress__bar' style={{ width: counter + "%" }}></div>
        </div>
        <div className='counter'>
          <h3>{counter}%</h3>
        </div>
      </div>
      <Navbar />
      <AnimatePresence mode='wait'>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Home isLoading={isLoading}/>} />
          <Route path='/about' element={<About isLoading={isLoading}/>}/>
          <Route path='/works' element={<Work isLoading={isLoading}/>}/>
          <Route path='/contact' element={<Contact isLoading={isLoading}/>}/>
          <Route path='/works/henbuk' element={<Henbuk isLoading={isLoading}/>}/>
          <Route path='/works/sass-bali-development' element={<SassBaliDevelopment isLoading={isLoading}/>}/>
          <Route path='/works/noa-bike' element={<NoaBike isLoading={isLoading}/>}/>
          <Route path='/works/spotlight' element={<Spotlight isLoading={isLoading}/>}/>
          <Route path='/works/digivition' element={<Digivition isLoading={isLoading}/>}/>
          <Route path='/works/exotic-komodo' element={<ExoticKomodo isLoading={isLoading}/>}/>
          <Route path='*' element={<PageNotFound isLoading={isLoading}/>}/>
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
