import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import { Sling as Hamburger } from 'hamburger-react'
import { useLocation } from "react-router-dom";

import {gsap} from 'gsap/src/all';
import SplitType from 'split-type';


import logo1 from '../../Assets/Image/logo-1.png'
import logo2 from '../../Assets/Image/logo-2.png'

const Navbar = () => {
    
    const [isOpen, setOpen] = useState(false)
    const navRef = useRef(null);

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    
    const myText = new SplitType('.menu__link a')
       

    useEffect(() => {
        // let ctx = gsap.context(() => {

        const tl = gsap.timeline({
            // paused: true
        });

        if(isOpen === true){
            gsap.to('.Navbar__logo a', {
                color: '#609FFF',
                duration: 0.1,
                ease: "power2.out",
            });
            gsap.to('.Navbar__logo .logo-1', {
                display: 'none',
                duration: 0,
                ease: "power2.out",
            });
            gsap.to('.Navbar__logo .logo-2', {
                display: 'block',
                duration: 0,
                ease: "power2.out",
            });
            gsap.to('.Navbar__logo a', {
                color: '#609FFF',
                duration: 0.1,
                ease: "power2.out",
            });
            gsap.to('.hamburger-react div', {
                background: '#609FFF',
                duration: 0.1,
                ease: "power2.out",
            });

            gsap.to('.menu', {
                scaleY: 1,
                transformOrigin: 'top',
                duration: 1,
                ease: "power2.out",
            });
            // gsap.to('.char', {
            //     y: 0,
            //     stagger : 0.05,
            //     delay: 0.2,
            //     duration: .5,
            //     ease: "power2.out"
            // });
            gsap.from('.nav-link .word .char', {
                y: 155,
                stagger : 0.05,
                delay: 0.2,
                duration: 0.5,
                ease: "power1.out",
            });

            gsap.to('.menu__divider', {
                scaleY: 1,
                duration: 1,
                ease: "power2.out",
                delay: 0.6,
                transformOrigin: 'top'
            })

            gsap.to('.menu__infoItem', {
                x: 0,
                opacity: 1,
                stagger: 0.2,
                duration: 1,
                ease: "power2.out",
                delay: 0.5,
            })

            gsap.to('.menu__footer', {
                opacity: 1,
                duration: 1,
                ease: "power2.out",
                delay: 1,
            })
        }

        else if(isOpen === false){
            gsap.to('.nav-link .word', {
                x: -255,
                stagger : 0.05,
                delay: 0.2,
                duration: 1,
                ease: "power2.out",
            });
            gsap.to('.Navbar__logo a', {
                color: '#FAFAF6',
                duration: 0.1,
                ease: "power2.out",
                delay: 0.6,
            });
            gsap.to('.Navbar__logo .logo-1', {
                display: 'block',
                delay: 1.2,
                duration: 0,
                ease: "power2.out",
            });
            gsap.to('.Navbar__logo .logo-2', {
                display: 'none',
                delay: 1.2,
                duration: 0,
                ease: "power2.out",
            });
            gsap.to('.hamburger-react div', {
                background: '#FAFAF6',
                duration: 0.3,
                ease: "power2.out",
                delay: 0.6,
            });
            
            gsap.to('.menu', {
                scaleY: 0,
                transformOrigin: 'top',
                duration: 1,
                ease: "power2.out",
                delay : 0.6
            });

            gsap.to('.menu__divider', {
                scaleY: 0,
                duration: 1,
                ease: "power2.out",
                delay: 0.1,
                transformOrigin: 'top'
            })

            gsap.to('.menu__infoItem', {
                x: -220,
                opacity: 0,
                stagger: 0.2,
                duration: 1,
                ease: "power2.out",
                delay: 0.1,
            })

            gsap.to('.menu__footer', {
                opacity: 0,
                duration: 1,
                ease: "power2.out",
                delay: 0.1,
            })

            
        }
        //   });

        //   return () => ctx.revert();
    }, [isOpen])

    // console.log(isOpen)
    

    return (
        <>
            <header className='Navbar' id='Navbar'>
                <div className='Navbar__container container'>
                    <div className='Navbar__data'>
                        <div className='Navbar__logo'>
                            <Link to='/'>
                                <img src={logo1}  className='logo logo-1' alt='Aditya Semara | Logo' />
                                <img src={logo2}  className='logo logo-2' alt='Aditya Semara | Logo' />
                            </Link>
                        </div>
                        <div className='menu__btn'>
                            <Hamburger  size={24} distance="lg" toggled={isOpen} toggle={setOpen} onToggle={toggled => {
                                if (toggled) {
                                    setOpen(true)

                                } else {
                                    setOpen(false)
                                }
                            }}/>
                        </div>
                    </div>
                </div>
            </header>

            <nav className='menu' id='menu'>
                <div className='menu__container container'>
                    <div className='menu__data'>
                        <ul className='menu__links'>
                            <li className='menu__link'onClick={(el) => {
                                setOpen(false)
                                // removeMenu()
                            }}>
                                <Link className={splitLocation[1] === "" ? " nav-link active-link" : "nav-link"} to='/'>
                                    Home
                                </Link>
                            </li>
                            <li className='menu__link'onClick={(el) => {
                                setOpen(false)
                                // removeMenu()
                            }}>
                                <Link className={splitLocation[1] === "about" ? " nav-link active-link" : "nav-link"} to='/about'>
                                    About Me
                                </Link>
                            </li>
                            <li className='menu__link'onClick={(el) => {
                                setOpen(false)
                                // removeMenu()
                            }}>
                                <Link className={splitLocation[1] === "works" ? " nav-link active-link" : "nav-link"} to='/works'>
                                    Works
                                </Link>
                            </li>
                            {/* <li className='menu__link'onClick={(el) => {
                                setOpen(false)
                                // removeMenu()
                            }}>
                                <Link className={splitLocation[1] === "expertise" ? " nav-link active-link" : "nav-link"} to='/'>
                                    Expertise
                                </Link>
                            </li> */}
                            <li className='menu__link'onClick={(el) => {
                                setOpen(false)

                                // removeMenu()

                            }}>
                                <Link className={splitLocation[1] === "contact" ? " nav-link active-link" : "nav-link"} to={`mailto:adityasemara.work@gmail.com?subject=Collaboration%20Opportunity&body=${encodeURIComponent("Hello there,\n\nI'm interested in exploring a potential collaboration on a project. Could we discuss further details?\n\nThank you")}`}>
                                    Contact
                                </Link>
                            </li>
                        </ul>
                        <div className='menu__infoContainer'>
                            <div className='menu__divider'></div>
                            <div className='menu__info'>
                                <div className='menu__infoItem'>
                                    <span className='info__title'>Want to Start a Project ?</span>
                                    <a href={`mailto:adityasemara.work@gmail.com?subject=Collaboration%20Opportunity&body=${encodeURIComponent("Hello there,\n\nI'm interested in exploring a potential collaboration on a project. Could we discuss further details?\n\nThank you")}`} className='info__talk'>Let’s Talk</a>
                                </div>
                                <div className='menu__infoItem'>
                                    <span className='info__title'>Get In Touch</span>
                                    <ul className='info__link'>
                                        <li className='info__links'>
                                            <a href={`mailto:adityasemara.work@gmail.com?subject=Collaboration%20Opportunity&body=${encodeURIComponent("Hello there,\n\nI'm interested in exploring a potential collaboration on a project. Could we discuss further details?\n\nThank you")}`}>adityasemara.work@gmail.com</a>
                                        </li>
                                        <li className='info__links'>
                                            <a href='/'>+62 8123 7593 074</a>
                                        </li>
                                        <li className='info__links'>
                                            <p>Denpasar, Bali</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='menu__footer'>
                        <span>Design By: Aditya Semara</span>
                        <div className="menu__social">
                            <a href="https://www.linkedin.com/in/i-wayan-aditya-semara-putra/" className="social__link"><i className='bx bxl-linkedin'></i></a>
                            <a href="https://www.instagram.com/iwyn.adityasp/" className="social__link"><i className='bx bxl-instagram'></i></a>
                            <a href="https://www.facebook.com/wayanaditya.semara" className="social__link"><i className='bx bxl-facebook'></i></a>
                        </div>
                    </div>
                </div>
            </nav>
    
            {/* <div className='nav'>
                <div className='logo'>
                    <Link className='nav-link' to='/'>Aditya</Link>
                </div>
                <div className='nav-links'>
                    <div className='nav-items'>
                        <Link className='nav-link' to='/'>
                            Home
                        </Link>
                    </div>
                    <div className='nav-items'>
                        <Link className='nav-link' to='/about'>
                            About
                        </Link>
                    </div>
                    <div className='nav-items'>
                        <Link className='nav-link' to='/contact'>
                            Contact
                        </Link>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Navbar;