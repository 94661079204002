// import { motion } from 'framer-motion';

// const transition = (OurComponent) => {
//   return ({ isLoading }) => (
//     <>
//       {/* Teruskan properti isLoading ke OurComponent */}
//       <OurComponent isLoading={isLoading} />

//       <motion.div
//         className='slide-in'
//         initial={{ scaleY: 0 }}
//         animate={{ scaleY: 0 }}
//         exit={{ scaleY: 1 }}
//         transition={{ duration: 1, ease: [0.22, 1, 0.36, 1], staggerChildren: 0.2 }}
//       >
//         <motion.div
//           className='slide-in2'
//           initial={{ scaleY: 0 }}
//           animate={{ scaleY: 0 }}
//           exit={{ scaleY: 1 }}
//           transition={{ duration: 1, ease: [0.22, 1, 0.36, 1], delay: 0.1 }}
//         ></motion.div>
//       </motion.div>

//       <motion.div
//         className='slide-out'
//         initial={{ scaleY: 1 }}
//         animate={{ scaleY: 0 }}
//         exit={{ scaleY: 0 }}
//         transition={{ duration: 1.7, ease: [0.22, 1, 0.36, 1], delay: 0.2 }}
//       >
//         <motion.div
//           className='slide-out2'
//           initial={{ scaleY: 1 }}
//           animate={{ scaleY: 0 }}
//           exit={{ scaleY: 0 }}
//           transition={{ duration: 1.7, ease: [0.22, 1, 0.36, 1], delay: 0.2 }}
//         ></motion.div>
//       </motion.div>
//     </>
//   );
// };

// export default transition;

import { motion } from 'framer-motion';

const transition = (OurComponent) => {
  return ({ isLoading }) => (
    <>
      {/* Pass the isLoading prop to OurComponent */}
      <OurComponent isLoading={isLoading} />

      <motion.div
        className='slide-in'
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1], staggerChildren: 0.2 }}
      >
        <motion.div
          className='slide-in2'
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 1 }}
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1], delay: 0.1 }}
        ></motion.div>
      </motion.div>

      <motion.div
        className='slide-out'
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 2, ease: [0.22, 1, 0.36, 1], delay: 0.2 }}
      >
        <motion.div
          className='slide-out2'
          initial={{ scaleY: 1 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 0 }}
          transition={{ duration: 2, ease: [0.22, 1, 0.36, 1], delay: 0.2 }}
        ></motion.div>
      </motion.div>
    </>
  );
};

export default transition;
